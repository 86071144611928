// ** Packages **
import { Path, PathValue } from "react-hook-form";

// ** Enum **
import { ATTACHMENT_FILE_TYPES } from "../enums";

// ** Type **
import { Option } from "../types/formField.types";
import { MultiValue, SingleValue } from "react-select";

export const isMultiValue = <T>(
  arg: MultiValue<T> | SingleValue<T>
): arg is MultiValue<T> => Array.isArray(arg);

export const isSingleValue = <T>(
  arg: MultiValue<T> | SingleValue<T>
): arg is SingleValue<T> => !Array.isArray(arg);

export const setNullValue = (value: any) => {
  if (value === undefined || value === null || value === "") {
    return null;
  }
  return value;
};

export const reactDatePickerSelectedDate = (
  date: PathValue<
    Record<string, unknown>,
    Path<Record<string, unknown>> & (string | undefined)
  >
) => {
  return (typeof date === "number" ||
    typeof date === "string" ||
    date instanceof Date) &&
    !Number.isNaN(new Date(date).getDate())
    ? new Date(date)
    : undefined;
};

export const checkFileFormat = (props: {
  allowedFormat: Option[];
  errorMsgArr: string[];
  type: string;
}) => {
  const { allowedFormat, errorMsgArr, type } = props;
  let isAllowedFormat = allowedFormat?.find(
    (format) => format.value === type
  )?.label;

  if (!allowedFormat?.length) {
    isAllowedFormat = Object.keys(ATTACHMENT_FILE_TYPES).find(
      (key) => (ATTACHMENT_FILE_TYPES as any)[key] === type
    );
  }

  const formatString = allowedFormat?.length
    ? allowedFormat.map((format) => format.label.trim())
    : Object.keys(ATTACHMENT_FILE_TYPES).map(
        (key) => `${key.split("_")[0]} ${key.split("_")[1] || ""}`
      );

  if (!isAllowedFormat && errorMsgArr.join(",") !== formatString.join(",")) {
    return formatString;
  }

  return [];
};

export const fileSizeGenerator = (fileSize: number) => {
  const sizeType = ["KB", "MB", "GB", "bytes"];
  if (fileSize < 1024) {
    return { size: fileSize.toFixed(1), sizeType: sizeType[3] }; // Display in BYTES
  }

  let i = -1;
  let size = fileSize;
  while (size > 1024) {
    size /= 1024;
    i++;
  }

  return { size: Math.max(size, 0.1).toFixed(1), sizeType: sizeType[i] };
};
