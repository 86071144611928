const Product = () => {
  return (
    <>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="">
        <path d="M26.0079 6.8718L18.8889 2.76923C17.0317 1.74359 14.8651 1.74359 13.0079 2.76923L5.88889 6.8718C4.13492 7.89744 3 9.84615 3 12V20.1026C3 22.1538 4.13492 24.1026 5.88889 25.1282L13.0079 29.2308C14.8651 30.2564 17.0317 30.2564 18.8889 29.2308L26.0079 25.1282C27.8651 24.1026 28.8968 22.1538 29 20.1026V12C28.8968 9.84615 27.8651 7.89744 26.0079 6.8718ZM14.246 4.82051C15.381 4.20513 16.7222 4.20513 17.754 4.82051L24.873 8.92308C25.0794 9.02564 25.1825 9.12821 25.3889 9.33333L18.0635 13.5385C16.8254 14.2564 15.1746 14.2564 13.9365 13.5385L6.61111 9.33333C6.81746 9.12821 6.92063 9.02564 7.12698 8.92308L14.246 4.82051ZM7.12698 23.1795C5.99206 22.5641 5.37302 21.4359 5.37302 20.1026V12C5.37302 11.7949 5.37302 11.5897 5.47619 11.3846L12.8016 15.5897C13.4206 16 14.1429 16.2051 14.8651 16.3077V27.4872C14.6587 27.3846 14.4524 27.3846 14.246 27.2821L7.12698 23.1795ZM26.627 20.1026C26.627 21.3333 25.9048 22.5641 24.873 23.1795L17.754 27.2821C17.5476 27.3846 17.3413 27.4872 17.1349 27.4872V16.3077C17.8571 16.2051 18.5794 15.8974 19.1984 15.5897L26.5238 11.3846C26.5238 11.5897 26.627 11.7949 26.627 12V20.1026Z" />
      </svg>
    </>
  );
};
export default Product;
