// ** Packages **
import { useEffect, useState } from "react";

// ** Components  **
import NameBadge from "components/Badge/NameBadge";
import Spinner from "components/Loaders/Spinner";
import Icon from "components/Icon";

// ** CSS **
// import "./style/avatar.css";

// ** Service **
import { getPresignedImageUrl } from "services/wasabi.service";

// ** Types **
import { IconTypes } from "components/Icon/types";

type Props = {
  imgPath?: string | File | null;
  avatarWrapperClassName?: string;
  avatarInnerWrapperClassName?: string;
  imgClassName?: string;
  imgElementClass?: string;
  imgLoaderWrapperClass?: string;
  noImgIconWrapperClass?: string;
  noImgIconColor?: string;
  NoNameLetterWrapperClass?: string;
  NoNameLetterClass?: string;
  OnlineStatusClass?: string;
  serverPath?: boolean;
  first_name?: string;
  last_name?: string;
  disableLoader?: boolean;
  color?: string;
  defaultType?: string;
  smallLoaderWrapperClassName?: string;
  iconName?: IconTypes;
};

// get file String and get file object url
const GetFilePath = (file: string | File, serverPath = false) => {
  if (typeof file === "string") {
    if (serverPath) {
      return `${file}`;
    }
  } else if (file) {
    return URL.createObjectURL(file);
  } else {
    return file;
  }
};

const Image = (props: Props) => {
  const {
    imgPath = "",
    avatarWrapperClassName = "",
    avatarInnerWrapperClassName = "",
    imgClassName = "",
    imgElementClass = "",
    imgLoaderWrapperClass = "",
    noImgIconWrapperClass = "",
    noImgIconColor = "",
    NoNameLetterWrapperClass = "",
    NoNameLetterClass = "",
    OnlineStatusClass = "",
    serverPath = false,
    disableLoader = false,
    first_name,
    last_name,
    color,
    defaultType = "wasabi",
    smallLoaderWrapperClassName,
    iconName = "user",
  } = props;

  // ** States **
  const [fetchError, setFetchError] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const [isMounted, setIsMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imageURL, setImageURL] = useState<string | File>("");

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    }
  }, []);

  useEffect(() => {
    if (imgPath && typeof imgPath === "string" && serverPath) {
      loadServerImage(imgPath);
    } else {
      setImageURL(imgPath || "");
    }
  }, [imgPath, serverPath]);

  const loadServerImage = async (path: string) => {
    setIsLoading(true);
    const result = await getPresignedImageUrl(path);
    setIsLoading(false);
    setImageURL(result || "");
  };

  const imgComponent = () => {
    return imageURL ? (
      <div className={`img__element ${imgElementClass}`}>
        <img
          className={`${!isImageLoaded ? "hidden" : "block"} ${imgClassName}`}
          src={
            defaultType === "wasabi"
              ? GetFilePath(imageURL, serverPath)
              : `${imgPath}`
          }
          alt=""
          onLoad={() => setIsImageLoaded(true)}
          onError={() => {
            setFetchError(true);
            setIsImageLoaded(true);
          }}
        />
      </div>
    ) : (
      <>
        <div className={`noImg__icon ${noImgIconWrapperClass}`}>
          <Icon fill={noImgIconColor} name={iconName} />
        </div>
      </>
    );
  };

  return (
    <div className={`avatar__Img ${avatarWrapperClassName}`}>
      <div className={`inner__wrapper ${avatarInnerWrapperClassName}`}>
        {fetchError ? (
          <>
            <div className={`noImg__icon ${noImgIconWrapperClass}`}>
              <Icon name={iconName} />
            </div>
          </>
        ) : (
          (isLoading || (!disableLoader && !isImageLoaded && imageURL)) && (
            <>
              <div className={`img__loader__wrapper ${imgLoaderWrapperClass}`}>
                <div className="inner__wrapper">
                  <Spinner
                    smallLoaderWrapperClassName={smallLoaderWrapperClassName}
                  />
                </div>
              </div>
            </>
          )
        )}

        {isMounted && !fetchError && !isLoading && (
          <>
            {!imageURL && (first_name || last_name) ? (
              <NameBadge
                color={color}
                LastName={last_name}
                FirstName={first_name}
                NoNameLetterClass={NoNameLetterClass}
                NoNameLetterWrapperClass={NoNameLetterWrapperClass}
              />
            ) : (
              imgComponent()
            )}
          </>
        )}
        <span className={`online__status hidden ${OnlineStatusClass}`} />
      </div>
    </div>
  );
};

export default Image;
