// ** Packages **
import { useState } from "react";

// ** Components **
import { InputField } from "components/FormField";

// ** Types **
import { TextInputProps } from "components/FormField/types/formField.types";

const PasswordField = <TFormValues extends Record<string, unknown>>(
  fieldProps: TextInputProps<TFormValues>
) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div>
      <InputField
        {...{
          ...fieldProps,
          onIconClick: () => {
            togglePasswordVisibility();
          },
          isIconRight: false,
          icon: isPasswordVisible ? "eye" : "lock",
        }}
        type={isPasswordVisible ? "text" : "password"}
      />
    </div>
  );
};

export default PasswordField;
