// ** Packages **
import { AxiosRequestConfig } from "axios";

// ** Axios **
import { useAxiosPost } from "hooks/useAxios";

const STATE_API_BASE_PATH = "/state";

export const useFetchStateAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const fetchState = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${STATE_API_BASE_PATH}/list`, data, config);
  };
  return { fetchState, isLoading, isError, isSuccess };
};
