// ** Custom Component **
import { InputField } from "components/FormField";

// ** Type **
import { TextInputProps } from "components/FormField/types/formField.types";

const EmailField = <TFormValues extends Record<string, unknown>>(
  fieldProps: TextInputProps<TFormValues>
) => {
  return <InputField {...fieldProps} type="email" />;
};

export default EmailField;
