// ** CSS **
// import "./style/actionButton.css";

// ** Component **
import Icon from "components/Icon";

// ** Type **
import { IconTypes } from "components/Icon/types";
import { ButtonHTMLAttributes } from "react";

type PropsType = {
  icon: IconTypes;
  fill?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const IconButton = (props: PropsType) => {
  const { icon, className = "", fill = "", type = "button", ...rest } = props;
  return (
    <button type={type} {...rest} className={`action__btn ${className}`}>
      <Icon fill={fill} name={icon} />
    </button>
  );
};
export default IconButton;
