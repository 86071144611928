// ** Packages **
import _ from "lodash";

// ** Service **
import { useFetchStateAPI } from "services/states.service";

// ** Type **
import { AsyncSelectGetOptionsType } from "components/FormField/types/formField.types";

// ** Constant **
import { OPTION_LISTING_DATA_LIMIT } from "constants/dataLimit.constant";

const useFetchStatesOptions = () => {
  // ** Api **
  const { fetchState, isLoading } = useFetchStateAPI();

  const getStatesOption: AsyncSelectGetOptionsType = async (option) => {
    const { data } = await fetchState({
      query: {
        select: "id,name",
        limit: OPTION_LISTING_DATA_LIMIT,
        searchFields: "name",
        page: option?.page,
        searchText: option?.search,
        sort: "name",
      },
    });

    if (_.isArray(data?.rows)) {
      const stateOptions = (data.rows || []).map(
        (state: { name: any; id: any }) => ({
          label: state?.name,
          value: state.id,
        })
      );

      return {
        option: stateOptions,
        count: data.count,
      };
    }
  };
  return { getStatesOption, isLoading };
};

export default useFetchStatesOptions;
