// ** Custom Component **
import Label from "components/FormField/components/common/Label";
import HelperText from "components/FormField/components/common/HelperText";

// ** Types **
import { ToggleButtonPropsTypes } from "components/FormField/types/formField.types";

const ToggleButtonField = <TFormValues extends Record<string, unknown>>(
  fieldProps: ToggleButtonPropsTypes<TFormValues>
) => {
  const {
    id,
    name,
    errors,
    className = "",
    errorClass = "",
    labelClass = "",
    textClassName = "",
    disabled = false,
    wrapperClass = "",
    label,
    register,
    onChange = () => ({}),
    checked,
  } = fieldProps;

  return (
    <>
      <div
        className={`toggleSwitch__btn ${disabled ? "disabled" : ""} ${
          !label ? "without__label" : ""
        } ${wrapperClass}`}
        key={label?.toString()}
      >
        <input
          id={id}
          type="checkbox"
          autoComplete="off"
          disabled={disabled}
          onChange={onChange}
          name={name}
          className={className}
          checked={checked}
          {...(register && register(name))}
        />
        {label ? (
          <Label id={id} label={label} labelClass={labelClass} textClassName={textClassName} />
        ) : (
          <label className="label__carpet" />
        )}
      </div>
      {errors?.message && (
        <HelperText
          helperText={errors?.message || ""}
          helperTextClass={`error__text ${errorClass}`}
        />
      )}
    </>
  );
};

export default ToggleButtonField;
