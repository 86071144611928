// ** Packages  **
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// ** Component **
import Icon from "components/Icon";
import Image from "components/Image";
import Spinner from "components/Loaders/Spinner";

// ** Redux **
import { getCurrentUser } from "redux/slices/authSlice";

// ** Hooks **
import { useToggleDropdown } from "hooks/useToggleDropdown";
import useAuth from "modules/Auth/hooks/useAuth";

// ** Constant **
import { PRIVATE_NAVIGATION } from "constants/navigation.constant";

// ** Util **
import { setUrlParams } from "utils";

const ProfileToggle = () => {
  const navigate = useNavigate();
  const { logout, logoutLoading } = useAuth();
  const user = useSelector(getCurrentUser);

  const { dropdownRef, isDropdownOpen, toggleDropdown } = useToggleDropdown();

  return (
    <div className="profile__wrapper flex relative" ref={dropdownRef}>
      <button
        className="w-[40px] h-[40px] ml-[12px] cursor-pointer sm:w-[34px] sm:h-[34px] sm:ml-[12px]"
        onClick={() => toggleDropdown()}
      >
        <Image
          imgPath={user?.profile_image || ""}
          first_name={user?.first_name || ""}
          last_name={user?.last_name || ""}
          avatarWrapperClassName="!w-full !h-full"
          avatarInnerWrapperClassName="!rounded-full"
          NoNameLetterClass="!text-[14px] !text-primaryColor"
          serverPath
        />
      </button>
      {isDropdownOpen && (
        <div className="carperDropdown absolute top-[100%] right-0 pt-[10px] z-[6]">
          <div className="carperDropdown__inner w-[150px]">
            <div className="carperDropdown__item">
              <div
                className="carperDropdown__link"
                onClick={() => {
                  if (user?.id) {
                    navigate(
                      setUrlParams(PRIVATE_NAVIGATION.TEAM.EDIT, user.id)
                    );
                    toggleDropdown();
                  }
                }}
              >
                <Icon name="user" />
                <span className="carperDropdown__name">Profile</span>
              </div>
            </div>
            <div className="carperDropdown__item">
              <div
                className="carperDropdown__link"
                onClick={() => {
                  logout();
                  toggleDropdown();
                }}
              >
                <Icon name="logout" />
                <span className="carperDropdown__name">Log Out</span>
                {logoutLoading && <Spinner />}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileToggle;
