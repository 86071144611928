// ** Packages **
import Tippy from "@tippyjs/react";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Instance, Placement } from "tippy.js";

// ** CSS **
// import "./style/tippyDropdown.css";

const defaultTippyProps = {
  theme: "light",
};
type PropsType = {
  children: React.ReactElement<any>;
  content: ({
    close,
    isOpen,
    setIsOpen,
  }: {
    close: () => void;
    isOpen?: boolean;
    setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  }) => React.ReactElement<any> | JSX.Element[];
  hideOnClick?: boolean;
  interactive?: boolean;
  placement?: Placement;
  className?: string;
  zIndex?: number;
  appendTo?: Element | "parent" | ((ref: Element) => Element);
  dynamicTippyProps?: {
    delay: number;
    duration: number;
    animation: boolean;
  };
  dependentClose?: boolean;
  searchRef?: React.MutableRefObject<string>;
  customOnClick?: (e: {
    isEnabled: boolean;
    isVisible: boolean;
    isDestroyed: boolean;
    isMounted: boolean;
    isShown: boolean;
  }) => void;
};

const TippyDropdown = forwardRef((props: PropsType, ref) => {
  const {
    children,
    content,
    hideOnClick = true,
    placement = "bottom-start",
    className = "",
    appendTo,
    dynamicTippyProps,
    dependentClose = false,
    zIndex = 9999,
    customOnClick,
    interactive = false,
    searchRef,
  } = props;
  // ** Ref **
  const instanceRef = useRef<Instance>();

  // ** States **
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (hideOnClick) {
      document.addEventListener("mousedown", onClick, true);
      return () => {
        document.removeEventListener("mousedown", onClick, true);
      };
    }
    return undefined;
  });

  useImperativeHandle(
    ref,
    () => ({
      open: isOpen,
      setIsOpen,
    }),
    [isOpen]
  );
  const onClick = (event: any) => {
    if (instanceRef.current) {
      const { popper, reference, state } = instanceRef.current;

      if (
        !popper.contains(event.target) &&
        !reference.contains(event.target) &&
        !(state.isVisible && reference.contains(event.target))
      ) {
        if (searchRef) {
          searchRef.current = "";
        }
        close();
      }
    }
  };

  const open = () => {
    if (instanceRef.current) {
      const { state } = instanceRef.current;
      customOnClick?.(state);
      if (!state.isVisible) {
        setIsOpen(true);
      } else {
        close();
      }
    }
  };

  const close = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (!dependentClose) {
      close();
    }
  }, [dependentClose]);

  const tippyContent = content({ close, isOpen, setIsOpen });

  return (
    <Tippy
      className={`tippy__dropdown ${className}`}
      {...defaultTippyProps}
      {...dynamicTippyProps}
      onCreate={(instance) => {
        instanceRef.current = instance;
      }}
      interactive={interactive}
      visible={isOpen}
      content={tippyContent}
      placement={placement}
      appendTo={appendTo}
      zIndex={zIndex}
    >
      {React.cloneElement(children, { onClick: open })}
    </Tippy>
  );
});

export default TippyDropdown;
