// ** Packages ** //
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";

// ** Components ** //
import Icon from "components/Icon";

// ** Redux **
import { setSideBarToggle } from "redux/slices/generalSettingSlice";

// ** Constants ** //
import { SettingMenuItemType } from "../constants";

// ** Types **
type Props = {
  subItems: SettingMenuItemType[];
};

const SubMenu = (props: Props) => {
  const { subItems } = props;

  // ** Vars **
  const dispatch = useDispatch();
  const location = useLocation();
  const pathString = location.pathname;

  // ** States **
  const [activeToggle, setActiveToggle] = useState(false);
  const [openSubmenuIndex, setOpenSubmenuIndex] = useState<number | null>(null);

  useEffect(() => {
    subItems.forEach((subItem, i) => {
      if (checkActive(subItem)) {
        setOpenSubmenuIndex(i);
      }
    });
  }, [pathString, subItems]);

  const toggleSubmenu = (index: number) => {
    setOpenSubmenuIndex(index === openSubmenuIndex ? null : index);
  };

  const checkActive = (subItem: any) => {
    return (
      pathString.includes(subItem.path) ||
      pathString.includes(subItem.activeMatchValue)
    );
  };

  return (
    <div className="setting__menu__items sub__menu">
      {subItems.map((subItem, index) => {
        const isActive = checkActive(subItem);
        const isSubmenuOpen = index === openSubmenuIndex;
        return (
          <div className={`setting__menu__item `} key={subItem.path}>
            {subItem.subItems?.length ? (
              <div
                className={`setting__menu__link ${
                  isActive ? "menu__is__open" : ""
                }`}
                onClick={() => {
                  toggleSubmenu(index);
                  setActiveToggle(!activeToggle);
                }}
              >
                <span className="setting__menu__text">{subItem.text}</span>
                {subItem.subItems && (
                  <Icon className="down__arrow" name={subItem.icon} />
                )}
              </div>
            ) : (
              <Link
                to={subItem.path}
                className={`setting__menu__link ${
                  isActive ? "menu__is__open" : ""
                }`}
                onClick={() => {
                  toggleSubmenu(index);
                  setActiveToggle(!activeToggle);
                  dispatch(setSideBarToggle({ isSideBarOpen: false }));
                }}
              >
                <span className="setting__menu__text">{subItem.text}</span>
                {subItem.subItems ? (
                  <Icon className="down__arrow" name={subItem.icon} />
                ) : null}
              </Link>
            )}
            {isSubmenuOpen &&
              subItem.subItems &&
              subItem.subItems.length > 0 && (
                <SubMenu subItems={subItem.subItems} />
              )}
          </div>
        );
      })}
    </div>
  );
};

export default SubMenu;
