// ** Packages **
import { Controller } from "react-hook-form";

// ** CSS **
// import "./style/input.css";

// ** components **
import HelperText from "components/FormField/components/common/HelperText";
import Label from "components/FormField/components/common/Label";
import Icon from "components/Icon";

// ** Types **
import { NumberFieldPropsTypes } from "components/FormField/types/formField.types";

const NumberField = <TFormValues extends Record<string, unknown>>(
  fieldProps: NumberFieldPropsTypes<TFormValues>
) => {
  const {
    icon,
    label,
    value,
    name,
    errors,
    control,
    onChange,
    errorClass = "",
    className = "",
    iconClass = "",
    labelClass = "",
    maxLength = 100,
    placeholder = "",
    required = false,
    disabled = false,
    wrapperClass = "",
    fieldWrapperClassName = "",
    fieldBGClassName = "",
    isIconRight = false,
  } = fieldProps;

  return (
    <div
      className={`field__wrapper ${
        errors?.message ? "field__has__error" : ""
      } ${disabled ? "disable" : ""} ${fieldWrapperClassName}`}
    >
      {label && (
        <Label label={label} required={required} labelClass={labelClass} />
      )}
      <div
        className={`field__inner__wrapper ${icon ? "field__has__icon" : ""} ${
          isIconRight ? "icon__right" : ""
        } ${wrapperClass}`}
      >
        {control && name ? (
          <Controller
            name={name}
            control={control}
            render={({
              field: {
                onChange: innerOnChange,
                value: innerValue,
                name: innerName,
              },
            }) => {
              return (
                <input
                  type="number"
                  id="number"
                  name={innerName}
                  autoComplete="off"
                  inputMode="numeric"
                  min={0}
                  maxLength={maxLength}
                  onBlur={innerOnChange}
                  onChange={(v) => {
                    innerOnChange(v);
                    onChange?.(v);
                  }}
                  placeholder={placeholder}
                  className={`input__carpet ${className}`}
                  value={(innerValue as string) || ""}
                />
              );
            }}
          />
        ) : (
          <input
            type="number"
            name={name}
            id="currency"
            autoComplete="off"
            inputMode="numeric"
            maxLength={maxLength}
            min={0}
            placeholder={placeholder}
            className={`input__carpet ${className}`}
            {...(onChange && { onChange })}
            defaultValue={(value as string) || ""}
            {...(onChange && { onBlur: onChange })}
          />
        )}
        {label && (
          <Label label={label} required={required} labelClass={labelClass} />
        )}
        <span className={`bgWrapper ${fieldBGClassName}`} />
        {icon && <Icon name={icon} className={iconClass || ""} />}
      </div>
      {errors?.message && (
        <HelperText
          helperText={errors?.message || ""}
          helperTextClass={`error__text ${errorClass}`}
        />
      )}
    </div>
  );
};

export default NumberField;
