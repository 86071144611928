import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

import { RootStateType } from "redux/store";

export type ToastSliceType = {
  message: string | null;
  type: string | null;
  id: number;
};

const initialState: ToastSliceType[] = [];

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    setToast(state: ToastSliceType[], action: PayloadAction<ToastSliceType>) {
      state.push({
        message: action.payload.message,
        type: action.payload.type,
        id: action.payload.id,
      });
    },
    removeToast(
      state: ToastSliceType[],
      action: PayloadAction<{ id: number }>
    ) {
      return state.filter((toast) => toast.id !== action.payload.id);
    },
  },
  extraReducers(builder) {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});
export const { reducer } = toastSlice;
export const { setToast, removeToast } = toastSlice.actions;
export const getToast = (state: RootStateType) => state.toast;

export default toastSlice;
