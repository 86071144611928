import Icon from "components/Icon";

const RecentReportCard = () => {
    return (
        <div className="recentReports__Card h-full bg-bgWhiteCarpet rounded-[20px] pt-[25px] pb-[20px] pl-[20px] pr-[25px] flex">
            <Icon className="w-[54px] h-[54px] mr-[18px] rounded-full shrink-0 p-[12px] before:bg-primaryColor before:opacity-10" name="graph" fill="var(--primaryColor)" />
            <div className="recentReports__right w-full">
                <div className="recentReports__header flex items-center justify-between">
                    <span className="recentReports__name inline-block text-[14px] font-AcuminPro__Regular text-primaryColor mr-[12px]">Installer Payables</span>
                    <span className="recentReports__time inline-block text-[14px] font-AcuminPro__Regular text-textExtraLight">08/28/23 - 8:08AM</span>
                </div>
                <h4 className="text-[18px] text-textDark font-AcuminPro__Regular mt-[5px]">Jared Doren - Jared's Flooring</h4>
            </div>
        </div>
    );
}
export default RecentReportCard;