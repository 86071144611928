// ** Packages **
import { Controller, Path } from "react-hook-form";

// ** CSS **
// import "./style/checkbox.css";

// ** Component **
import Label from "components/FormField/components/common/Label";
import HelperText from "components/FormField/components/common/HelperText";

// ** Types **
import { CheckboxPropsTypes } from "components/FormField/types/formField.types";
import { DynamicObjectType } from "types";
import Icon from "components/Icon";

const CheckBoxField = <TFormValues extends Record<string, unknown>>(
  fieldProps: CheckboxPropsTypes<TFormValues>
) => {
  const {
    id,
    name,
    errors,
    register,
    control,
    options = [],
    className = "",
    labelClass = "",
    required,
    fieldLabelClass = "",
    hasFieldLabel = true,
    fieldWrapperClassName = "",
    disabled = false,
    errorClass = "",
    wrapperClass = "",
    checkBoxMainClass = "",
    CustomCheckboxSingle = "",
    value: defaultValue,
    label: defaultLabel,
    onChange = () => ({}),
    defaultChecked = false,
    type,
  } = fieldProps;

  const renderInput = ({
    value: optionValue,
    selected,
    name,
  }: {
    value?: string | number | readonly string[];
    selected: boolean;
    name?: Path<TFormValues>;
  }) => {
    if (control && name) {
      return (
        <Controller
          name={name}
          control={control}
          render={({
            field: { onChange: innerOnChange, value: innerValue },
          }) => {
            let checked = false;

            if (!Array.isArray(innerValue)) {
              checked = innerValue as boolean;
            } else {
              const ids =
                ((innerValue as DynamicObjectType) || []).map(
                  (item: DynamicObjectType) =>
                    item.value ? item.value.toString() : item.toString()
                ) || [];

              checked = ids.includes(optionValue?.toString());
            }

            return (
              <input
                type={type || "checkbox"}
                id={id}
                autoComplete="off"
                value={optionValue}
                disabled={disabled}
                key={optionValue?.toString()}
                checked={checked}
                onChange={(e) => {
                  innerOnChange(e);
                  onChange(e);
                }}
                className={`input__carpet !pl-[50px] ${className}`}
                {...(register && register(name, { onChange }))}
              />
            );
          }}
        />
      );
    }
    return (
      <input
        id={id}
        value={optionValue}
        type={type || "checkbox"}
        autoComplete="off"
        disabled={disabled}
        onChange={onChange}
        className={className}
        {...(name && { name })}
        key={optionValue?.toString()}
        defaultChecked={selected}
        {...(register && name && register(name, { onChange }))}
      />
    );
  };

  const checkBoxWrapper = (wrapperProps: {
    label: string | undefined;
    value: string | number | readonly string[] | undefined;
    selected: boolean;
  }) => {
    const { label, value, selected } = wrapperProps;
    return (
      <div className={`customCheckbox__carpetSingle ${CustomCheckboxSingle}`}>
        <div
          className={`customCheckbox__carpet ${
            !label ? "without__label" : ""
          } ${wrapperClass} ${fieldWrapperClassName}`}
          key={label?.toString()}
        >
          {renderInput({
            name,
            value,
            selected: !!selected,
          })}
          {label ? (
            <Label id={id} label={label} labelClass={labelClass} />
          ) : (
            <label className="label__carpet" />
          )}
          <Icon
            className="relative before:content-[''] before:duration-300 before:absolute before:top-0 before:left-0 before:w-full before:h-full"
            name="checkArrowBold"
          />
        </div>
      </div>
    );
  };

  return (
    <div
      className={`checkBoxMainWrapper ${checkBoxMainClass} ${
        hasFieldLabel ? "field__wrapper" : ""
      }`}
    >
      {defaultLabel && hasFieldLabel ? (
        <Label
          id={id}
          label={defaultLabel}
          required={required}
          labelClass={fieldLabelClass}
        />
      ) : (
        <></>
      )}
      {!options.length
        ? checkBoxWrapper({
            label: defaultLabel,
            value: defaultValue,
            selected: defaultChecked,
          })
        : options.map((option) =>
            checkBoxWrapper({
              label: option.label,
              value: option?.value,
              selected: Boolean(option?.selected),
            })
          )}
      {errors?.message && (
        <HelperText
          helperText={errors?.message || ""}
          helperTextClass={`error__text ${errorClass}`}
        />
      )}
    </div>
  );
};

export default CheckBoxField;
