// ** Packages **
import { useLocation } from "react-router-dom";

// ** Components **
import Icon from "components/Icon";
import SidebarNavItem from "./SidebarNavItems";
import AuthGuard from "modules/Auth/components/AuthGuard";

// ** Constants **
import { BOTTOM_NAVIGATION_ITEM, TOP_NAVIGATION_ITEM } from "../constants";
import { ACTION } from "constants/entity.constant";

// ** Type **
import { SideBarPropsType } from "../types";

const Sidebar = (props: SideBarPropsType) => {
  const { sideBarToggle, redirectToDashboard } = props;
  const location = useLocation();
  const pathString = location.pathname;

  return (
    <aside className="sidebar">
      <div className="logoWrapper" onClick={() => redirectToDashboard()}>
        <img
          className="fullLogo cursor-pointer"
          src="https://www.651carpets.com/wp-content/uploads/2024/03/651carpet_logo.png"
          alt="651Carpet"
        />
        <img
          className="collapseLogo cursor-pointer"
          src="images/header__logo__collapse.png"
          alt="Carpet651"
        />
      </div>
      <div className="menuWrapper flex flex-wrap content-between min-h-[calc(100dvh_-_97px)] max-h-[calc(100dvh_-_97px)] overflow-y-auto hideScrollbar pb-[15px] lg:min-h-[calc(100dvh_-_70px)] lg:max-h-[calc(100dvh_-_70px)]">
        <div className="menuWrapper__sub w-full">
          {TOP_NAVIGATION_ITEM?.map((item) => {
            return item.module ? (
              <AuthGuard moduleName={item.module} permissionName={ACTION.READ}>
                <SidebarNavItem
                  key={item.path}
                  path={item.path}
                  icon={item.icon}
                  label={item.label}
                  currentPath={pathString}
                  onClick={() => sideBarToggle()}
                />
              </AuthGuard>
            ) : (
              <SidebarNavItem
                key={item.path}
                path={item.path}
                icon={item.icon}
                label={item.label}
                currentPath={pathString}
                onClick={() => sideBarToggle()}
              />
            );
          })}
        </div>
        <div className="menuWrapper__sub w-full">
          {BOTTOM_NAVIGATION_ITEM?.map((item) => {
            return item.module ? (
              <AuthGuard moduleName={item.module} permissionName={ACTION.READ}>
                <SidebarNavItem
                  key={item.path}
                  path={item.path}
                  icon={item.icon}
                  label={item.label}
                  currentPath={pathString}
                  onClick={() => sideBarToggle()}
                />
              </AuthGuard>
            ) : (
              <SidebarNavItem
                key={item.path}
                path={item.path}
                icon={item.icon}
                label={item.label}
                currentPath={pathString}
                onClick={() => sideBarToggle()}
              />
            );
          })}
        </div>
      </div>
      <Icon
        onClick={() => sideBarToggle()}
        className="mobileCloseBtn textDarkHoverWhite !absolute top-[10px] right-[-40px] w-[32px] h-[32px] rounded-full bg-bgWhiteCarpet p-[7px] hover:bg-primaryColor hidden lg:flex"
        name="close"
      />
    </aside>
  );
};

export default Sidebar;
