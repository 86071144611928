// ** packages **
import { useDispatch, useSelector } from "react-redux";

// ** Redux **
import { getToast, removeToast } from "redux/slices/toastSlice";

// ** Components **
import Icon from "components/Icon";

// ** Types **
import { IconTypes } from "components/Icon/types";

// ** CSS **
// import "./style/toast.css";

const Toast = (props: { icon?: IconTypes }) => {
  const { icon = "bell" } = props;
  const toastMessage = useSelector(getToast);
  const dispatch = useDispatch();

  return (
    <>
      {toastMessage.length && typeof toastMessage[0].message !== "object" ? (
        <div className="toastWrapper hideScrollbar">
          <div className="toastCN__absolute">
            {toastMessage.map((toast) => (
              <div
                key={toast.id}
                className={`toastItem show ${toast.type}`}
              >
                <div className="innerWrapper">
                  <Icon className="typeIcon" name={icon} />
                  <p className="toastText">{toast.message}</p>
                  <Icon
                    onClick={() => dispatch(removeToast({ id: toast.id }))}
                    className="closeBtn"
                    name="closeBold"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Toast;
