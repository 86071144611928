import { useEffect, useRef, useState } from "react";

type Props = {
  id?: string;
  className?: string;
};

export const useToggleDropdown = (props: Props = {}) => {
  const { id, className } = props;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownList, setIsDropdownList] = useState<{
    isOpen: boolean;
    id: number | null;
  }>({
    isOpen: false,
    id: null,
  });
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const toggleDropdownForList = ({
    id,
    isOpen,
  }: {
    id: number | null;
    isOpen: boolean;
  }) => {
    setIsDropdownList({ id, isOpen });
  };

  const handleClickOutside = (event: any) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownList({
        id: null,
        isOpen: false,
      });
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (id) {
      const element = document.getElementById?.(id);
      element?.addEventListener?.("mousedown", handleClickOutside);
    } else if (className) {
      const element = document.getElementsByClassName?.(className)?.[0];
      element?.addEventListener?.("mousedown", handleClickOutside);
    } else {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      if (id) {
        const element = document.getElementById?.(id);
        element?.addEventListener?.("mousedown", handleClickOutside);
      } else if (className) {
        const element = document.getElementsByClassName?.(className)?.[0];
        element?.addEventListener?.("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }
    };
  }, []);

  return {
    isDropdownOpen,
    toggleDropdown,
    dropdownRef,
    toggleDropdownForList,
    isDropdownList,
    setIsDropdownOpen,
  };
};
