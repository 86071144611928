import { ACTION, PERMISSION_MODULE } from "constants/entity.constant";
import useIsAuthorized from "../hooks/useIsAuthorized";

type Props = {
  moduleName?: PERMISSION_MODULE;
  permissionName: ACTION;
  children: JSX.Element;
};

const AuthGuard = (props: Props) => {
  const { moduleName, permissionName, children } = props;

  // ** Custom Hooks **
  const { isAuthorized } = useIsAuthorized();

  if (permissionName && moduleName) {
    const hasPermission = isAuthorized({ moduleName, permissionName });

    if (hasPermission) {
      return children;
    }
  }

  return <></>;
};

export default AuthGuard;
