const Lead = () => {
  return (
    <>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="">
        <path d="M21.8745 13.6124C17.3726 13.6124 13.749 17.1938 13.749 21.6434C13.749 26.093 17.3726 29.6744 21.8745 29.6744C26.3765 29.6744 30 26.093 30 21.6434C30 17.1938 26.3765 13.6124 21.8745 13.6124ZM21.8745 27.3953C18.6902 27.3953 16.0549 24.7907 16.0549 21.6434C16.0549 18.4961 18.6902 15.8915 21.8745 15.8915C25.0588 15.8915 27.6941 18.4961 27.6941 21.6434C27.6941 24.7907 25.0588 27.3953 21.8745 27.3953ZM23.851 21.969C24.2902 22.4031 24.4 23.1628 23.9608 23.5969C23.5216 24.031 22.7529 24.1395 22.3137 23.7054C22.3137 23.7054 22.2039 23.7054 22.2039 23.5969L20.9961 22.4031C20.7765 22.186 20.6667 21.8605 20.6667 21.5349V19.2558C20.6667 18.6047 21.2157 18.062 21.8745 18.062C22.5333 18.062 23.0824 18.6047 23.0824 19.2558V21.1008L23.851 21.969ZM30 10.1395V12.4186C30 13.0698 29.451 13.6124 28.7922 13.6124C28.1333 13.6124 27.5843 13.0698 27.5843 12.4186V10.1395C27.5843 8.18605 26.0471 6.66667 24.0706 6.66667H7.81961C5.84314 6.66667 4.30588 8.18605 4.30588 10.1395V11.3333H14.8471C15.5059 11.3333 16.0549 11.876 16.0549 12.5271C16.0549 13.1783 15.5059 13.7209 14.8471 13.7209H4.41569V24.1395C4.41569 26.093 5.95294 27.6124 7.92941 27.6124H12.5412C13.2 27.6124 13.749 28.155 13.749 28.8062C13.749 29.4574 13.2 30 12.5412 30H7.81961C4.63529 30 2 27.3953 2 24.2481V10.1395C2 6.99225 4.63529 4.3876 7.81961 4.3876H9.02745V3.1938C9.02745 2.54264 9.57647 2 10.2353 2C10.8941 2 11.4431 2.54264 11.4431 3.1938V4.3876H20.7765V3.1938C20.7765 2.54264 21.3255 2 21.9843 2C22.6431 2 23.1922 2.54264 23.1922 3.1938V4.3876H24.4C27.3647 4.3876 30 6.88372 30 10.1395Z" />
      </svg>
    </>
  );
};
export default Lead;
