import Button from "components/Button";
import {
  CheckBoxField,
  InputField,
  SelectField,
  TextField,
} from "components/FormField";
import Icon from "components/Icon";
import Image from "components/Image";
import Modal from "components/Modal";
import React, { useState } from "react";

const StaticModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => {
    setIsOpen(false);
  };
  const openModal = () => {
    setIsOpen(true);
  };

  return (
    <>
      <div className="mb-[30px]">
        <h3 className="text-[24px] font-AcuminPro__Bold text-textDark mb-[10px]">
          Modal:
        </h3>
        <Button className="primaryBtn" onClick={openModal}>
          Click me to open modal
        </Button>
      </div>
      {isOpen && (
        <Modal
          width={736}
          visible={isOpen}
          onCancel={() => closeModal()}
          onClose={() => closeModal()}
          iconHeaderIcon="bell"
          iconHeaderTitle="Dummy Text"
          // modalWrapperClass="modalBody__scrollDisable"
        >
          {/* lead salesman modal */}
          {/* modal width = 380 */}
          <div className="hidden">
            <div className="iconHeader mb-[30px]">
              <div className="flex flex-wrap items-center justify-center">
                <Icon
                  className="w-[50px] h-[50px] p-[10px] relative before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:rounded-full before:bg-primaryColor before:opacity-10"
                  name="user"
                />
                <h3 className="w-full text-[18px] text-textDark font-AcuminPro__Semibold text-center mt-[10px]">
                  Please select salesman
                </h3>
              </div>
            </div>
            <div className="userListing__select">
              <div className="userListing__item group relative overflow-hidden rounded-[14px] py-[15px] px-[15px] before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-primaryColor before:opacity-0 before:duration-300 hover:before:opacity-10">
                <div className="innerWrapper flex items-center relative z-[2] group-hover:pr-[40px]">
                  <Image
                    iconName="user"
                    // imgPath="/images/carpet-img.jpg"
                    NoNameLetterClass="!text-[22px] pt-[3px]"
                    avatarWrapperClassName="w-[32px] h-[32px] shrink-0 mr-[10px]"
                    avatarInnerWrapperClassName="!rounded-full"
                  />
                  <span className="userName relative top-[4px] inline-block w-full font-AcuminPro__Regular text-[17px] leading-[20px] text-textDark whitespace-pre overflow-hidden text-ellipsis">
                    Jill Mackly
                  </span>
                  <div className="addBtn inline-block hover:cursor-pointer absolute z-[2] top-[50%] translate-y-[-50%] right-0 opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto">
                    <Icon
                      className="primaryHoverWhite w-[28px] h-[28px] border-[2px] border-primaryColor rounded-full p-[4px] hover:before:bg-primaryColor"
                      name="plus"
                    />
                  </div>
                </div>
              </div>
              <div className="userListing__item group relative overflow-hidden rounded-[14px] py-[15px] px-[15px] before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-primaryColor before:opacity-0 before:duration-300 hover:before:opacity-10">
                <div className="innerWrapper flex items-center relative z-[2] group-hover:pr-[40px]">
                  <Image
                    iconName="user"
                    // imgPath="/images/carpet-img.jpg"
                    NoNameLetterClass="!text-[22px] pt-[3px]"
                    avatarWrapperClassName="w-[32px] h-[32px] shrink-0 mr-[10px]"
                    avatarInnerWrapperClassName="!rounded-full"
                  />
                  <span className="userName relative top-[4px] inline-block w-full font-AcuminPro__Regular text-[17px] leading-[20px] text-textDark whitespace-pre overflow-hidden text-ellipsis">
                    Jill Mackly
                  </span>
                  <div className="addBtn inline-block hover:cursor-pointer absolute z-[2] top-[50%] translate-y-[-50%] right-0 opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto">
                    <Icon
                      className="primaryHoverWhite w-[28px] h-[28px] border-[2px] border-primaryColor rounded-full p-[4px] hover:before:bg-primaryColor"
                      name="plus"
                    />
                  </div>
                </div>
              </div>
              <div className="userListing__item group relative overflow-hidden rounded-[14px] py-[15px] px-[15px] before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-primaryColor before:opacity-0 before:duration-300 hover:before:opacity-10">
                <div className="innerWrapper flex items-center relative z-[2] group-hover:pr-[40px]">
                  <Image
                    iconName="user"
                    // imgPath="/images/carpet-img.jpg"
                    NoNameLetterClass="!text-[22px] pt-[3px]"
                    avatarWrapperClassName="w-[32px] h-[32px] shrink-0 mr-[10px]"
                    avatarInnerWrapperClassName="!rounded-full"
                  />
                  <span className="userName relative top-[4px] inline-block w-full font-AcuminPro__Regular text-[17px] leading-[20px] text-textDark whitespace-pre overflow-hidden text-ellipsis">
                    Jill Mackly
                  </span>
                  <div className="addBtn inline-block hover:cursor-pointer absolute z-[2] top-[50%] translate-y-[-50%] right-0 opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto">
                    <Icon
                      className="primaryHoverWhite w-[28px] h-[28px] border-[2px] border-primaryColor rounded-full p-[4px] hover:before:bg-primaryColor"
                      name="plus"
                    />
                  </div>
                </div>
              </div>
              <div className="userListing__item group relative overflow-hidden rounded-[14px] py-[15px] px-[15px] before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-primaryColor before:opacity-0 before:duration-300 hover:before:opacity-10">
                <div className="innerWrapper flex items-center relative z-[2] group-hover:pr-[40px]">
                  <Image
                    iconName="user"
                    // imgPath="/images/carpet-img.jpg"
                    NoNameLetterClass="!text-[22px] pt-[3px]"
                    avatarWrapperClassName="w-[32px] h-[32px] shrink-0 mr-[10px]"
                    avatarInnerWrapperClassName="!rounded-full"
                  />
                  <span className="userName relative top-[4px] inline-block w-full font-AcuminPro__Regular text-[17px] leading-[20px] text-textDark whitespace-pre overflow-hidden text-ellipsis">
                    Jill Mackly
                  </span>
                  <div className="addBtn inline-block hover:cursor-pointer absolute z-[2] top-[50%] translate-y-[-50%] right-0 opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto">
                    <Icon
                      className="primaryHoverWhite w-[28px] h-[28px] border-[2px] border-primaryColor rounded-full p-[4px] hover:before:bg-primaryColor"
                      name="plus"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* lead salesman modal end */}

          {/* report details form modal */}
          {/* modal width = 415 */}
          <div className="hidden">
            <div className="iconHeader mb-[30px]">
              <div className="flex flex-wrap items-center justify-center">
                <Icon
                  className="w-[50px] h-[50px] p-[10px] relative before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:rounded-full before:bg-primaryColor before:opacity-10"
                  name="graph"
                />
                <h3 className="w-full text-[18px] text-textDark font-AcuminPro__Semibold text-center mt-[10px]">
                  G/L Detail Report
                </h3>
              </div>
            </div>
            <div className="mx-[-8px] flex flex-wrap">
              <div className="w-full px-[8px]">
                <SelectField
                  name="product_type"
                  fieldWrapperClassName="carperFieldBox"
                  label="Starting Account"
                  options={[
                    { value: "1", label: "Product Type 1" },
                    { value: "2", label: "Product Type 2" },
                    { value: "3", label: "Product Type 3" },
                  ]}
                />
              </div>
              <div className="w-full px-[8px]">
                <SelectField
                  name="product_type"
                  fieldWrapperClassName="carperFieldBox"
                  label="Ending Account"
                  options={[
                    { value: "1", label: "Product Type 1" },
                    { value: "2", label: "Product Type 2" },
                    { value: "3", label: "Product Type 3" },
                  ]}
                />
              </div>
              <div className="w-1/2 px-[8px]">
                <TextField
                  name="first_name"
                  autoComplete="off"
                  label="Beginning Period"
                  placeholder="Enter your name"
                  fieldWrapperClassName="carperFieldBox"
                />
              </div>
              <div className="w-1/2 px-[8px]">
                <TextField
                  name="first_name"
                  autoComplete="off"
                  label="Ending Period"
                  placeholder="Enter your name"
                  fieldWrapperClassName="carperFieldBox"
                />
              </div>
              <div className="w-full px-[8px]">
                <SelectField
                  name="product_type"
                  fieldWrapperClassName="carperFieldBox"
                  label="Select Year"
                  options={[
                    { value: "1", label: "Product Type 1" },
                    { value: "2", label: "Product Type 2" },
                    { value: "3", label: "Product Type 3" },
                  ]}
                />
              </div>
              <div className="w-full px-[8px]">
                <CheckBoxField
                  wrapperClass="customCheckbox__carpetMedium"
                  fieldLabelClass="!hidden"
                  label="Previous Year"
                />
              </div>
              <div className="w-full px-[8px]">
                <SelectField
                  name="product_type"
                  fieldWrapperClassName="carperFieldBox"
                  label="Print Source"
                  options={[
                    { value: "1", label: "Product Type 1" },
                    { value: "2", label: "Product Type 2" },
                    { value: "3", label: "Product Type 3" },
                  ]}
                />
              </div>
            </div>
          </div>
          {/* report details form modal end */}

          {/* customer note modal */}
          {/* modal width = 760 */}
          {/* you have to add modal class "customerNote__modal" */}
          <div className="hidden">
            <div className="iconHeader mb-[30px] mt-[-20px]">
              <div className="flex flex-wrap items-center justify-center">
                <Icon
                  className="w-[50px] h-[50px] p-[10px] relative before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:rounded-full before:bg-primaryColor before:opacity-10"
                  name="noteBig"
                />
                <h3 className="w-full text-[18px] text-textDark font-AcuminPro__Semibold text-center mt-[10px]">
                  Customer Notes
                </h3>
              </div>
            </div>
            <div className="noteListing px-[30px] max-h-[calc(100dvh_-_330px)] overflow-y-auto fancyScroll">
              <div className="noteItem flex items-start mb-[12px] last:mb-0">
                <div className="avatar__Img w-[40px] h-[40px] mr-[15px]">
                  <div className="inner__wrapper !rounded-full">
                    <div className="img__element">
                      <img src="/images/carpet-img.jpg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="rightCN inline-block max-w-[calc(100%_-_56px)] rounded-[12px] border-[1px] border-borderPrimary px-[20px] pt-[15px] pb-[10px]">
                  <p className="time text-[14px] text-primaryColor font-AcuminPro__Regular mb-[2px]">
                    JR - 11/17/23 1:03 PM
                  </p>
                  <p className="contentText text-[20px] text-textDark leading-[28px] font-AcuminPro__Regular">
                    Leslie would like additional Pine Valley remnants.
                  </p>
                </div>
              </div>
              <div className="noteItem flex items-start mb-[12px] last:mb-0">
                <div className="avatar__Img w-[40px] h-[40px] mr-[15px]">
                  <div className="inner__wrapper !rounded-full">
                    <div className="img__element">
                      <img src="/images/carpet-img.jpg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="rightCN inline-block max-w-[calc(100%_-_56px)] rounded-[12px] border-[1px] border-borderPrimary px-[20px] pt-[15px] pb-[10px]">
                  <p className="time text-[14px] text-primaryColor font-AcuminPro__Regular mb-[2px]">
                    JR - 11/17/23 1:03 PM
                  </p>
                  <p className="contentText text-[20px] text-textDark leading-[28px] font-AcuminPro__Regular">
                    Leslie would like additional Pine Valley remnants.
                  </p>
                </div>
              </div>
              <div className="noteItem flex items-start mb-[12px] last:mb-0">
                <div className="avatar__Img w-[40px] h-[40px] mr-[15px]">
                  <div className="inner__wrapper !rounded-full">
                    <div className="img__element">
                      <img src="/images/carpet-img.jpg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="rightCN inline-block max-w-[calc(100%_-_56px)] rounded-[12px] border-[1px] border-borderPrimary px-[20px] pt-[15px] pb-[10px]">
                  <p className="time text-[14px] text-primaryColor font-AcuminPro__Regular mb-[2px]">
                    JR - 11/17/23 1:03 PM
                  </p>
                  <p className="contentText text-[20px] text-textDark leading-[28px] font-AcuminPro__Regular">
                    Leslie would like additional Pine Valley remnants. Leslie
                    would like additional Pine Valley remnants.
                  </p>
                </div>
              </div>
              <div className="noteItem flex items-start mb-[12px] last:mb-0">
                <div className="avatar__Img w-[40px] h-[40px] mr-[15px]">
                  <div className="inner__wrapper !rounded-full">
                    <div className="img__element">
                      <img src="/images/carpet-img.jpg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="rightCN inline-block max-w-[calc(100%_-_56px)] rounded-[12px] border-[1px] border-borderPrimary px-[20px] pt-[15px] pb-[10px]">
                  <p className="time text-[14px] text-primaryColor font-AcuminPro__Regular mb-[2px]">
                    JR - 11/17/23 1:03 PM
                  </p>
                  <p className="contentText text-[20px] text-textDark leading-[28px] font-AcuminPro__Regular">
                    Leslie would like additional Pine Valley remnants additional
                    Pine.
                  </p>
                </div>
              </div>
              <div className="noteItem flex items-start mb-[12px] last:mb-0">
                <div className="avatar__Img w-[40px] h-[40px] mr-[15px]">
                  <div className="inner__wrapper !rounded-full">
                    <div className="img__element">
                      <img src="/images/carpet-img.jpg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="rightCN inline-block max-w-[calc(100%_-_56px)] rounded-[12px] border-[1px] border-borderPrimary px-[20px] pt-[15px] pb-[10px]">
                  <p className="time text-[14px] text-primaryColor font-AcuminPro__Regular mb-[2px]">
                    JR - 11/17/23 1:03 PM
                  </p>
                  <p className="contentText text-[20px] text-textDark leading-[28px] font-AcuminPro__Regular">
                    Leslie would like additional Pine Valley remnants.
                  </p>
                </div>
              </div>
              <div className="noteItem flex items-start mb-[12px] last:mb-0">
                <div className="avatar__Img w-[40px] h-[40px] mr-[15px]">
                  <div className="inner__wrapper !rounded-full">
                    <div className="img__element">
                      <img src="/images/carpet-img.jpg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="rightCN inline-block max-w-[calc(100%_-_56px)] rounded-[12px] border-[1px] border-borderPrimary px-[20px] pt-[15px] pb-[10px]">
                  <p className="time text-[14px] text-primaryColor font-AcuminPro__Regular mb-[2px]">
                    JR - 11/17/23 1:03 PM
                  </p>
                  <p className="contentText text-[20px] text-textDark leading-[28px] font-AcuminPro__Regular">
                    Leslie would like additional Pine Valley remnants.
                  </p>
                </div>
              </div>
              <div className="noteItem flex items-start mb-[12px] last:mb-0">
                <div className="avatar__Img w-[40px] h-[40px] mr-[15px]">
                  <div className="inner__wrapper !rounded-full">
                    <div className="img__element">
                      <img src="/images/carpet-img.jpg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="rightCN inline-block max-w-[calc(100%_-_56px)] rounded-[12px] border-[1px] border-borderPrimary px-[20px] pt-[15px] pb-[10px]">
                  <p className="time text-[14px] text-primaryColor font-AcuminPro__Regular mb-[2px]">
                    JR - 11/17/23 1:03 PM
                  </p>
                  <p className="contentText text-[20px] text-textDark leading-[28px] font-AcuminPro__Regular">
                    Leslie would like additional Pine Valley remnants.
                  </p>
                </div>
              </div>
            </div>
            <div className="writingArea relative border-t-[1px] border-t-borderPrimary">
              <TextField
                autoComplete="off"
                placeholder="Enter note here..."
                fieldWrapperClassName="!mb-0"
              />
              <button className="submitBtn absolute top-[50%] translate-y-[-50%] right-[30px] z-[2]">
                <Icon
                  className="w-[40px] h-[40px] rounded-full bg-primaryColor p-[8px] hover:bg-primaryColorDark"
                  fill="#ffffff"
                  name="paperPlaneRightFilled"
                />
              </button>
            </div>
          </div>
          {/* customer note modal end */}

          {/* image documents upload modal */}
          <div className="hidden">
            <div className="iconHeader mb-[30px] mt-[-20px]">
              <div className="flex flex-wrap items-center justify-center">
                <Icon
                  className="w-[50px] h-[50px] p-[10px] relative before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:rounded-full before:bg-primaryColor before:opacity-10"
                  name="imageSquare"
                />
                <h3 className="w-full text-[18px] text-textDark font-AcuminPro__Semibold text-center mt-[10px]">
                  Images
                </h3>
              </div>
            </div>
            <div className="pt-[10px] px-[30px] max-h-[calc(100dvh_-_330px)] overflow-y-auto fancyScroll">
              <div className="flex flex-wrap justify-between mx-[-10px] after:content-[''] after:flex-auto">
                <div className="w-[98px] h-[98px] relative mx-[10px] mb-[20px]">
                  <div className="w-full h-full overflow-hidden rounded-[16px]">
                    <img
                      className="w-full h-full object-cover object-center border-[2px] border-borderPrimary"
                      src="images/carpet-img.jpg"
                      alt=""
                    />
                  </div>
                  <Icon
                    className="primaryHoverWhite w-[24px] h-[24px] p-[6px] rounded-full bg-white !absolute z-[2] top-[-8px] right-[-8px] cursor-pointer before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:rounded-full before:bg-primaryColor before:opacity-10 hover:before:opacity-100"
                    name="closeBold"
                  />
                </div>
                <div className="w-[98px] h-[98px] relative mx-[10px] mb-[20px]">
                  <div className="w-full h-full overflow-hidden rounded-[16px]">
                    <img
                      className="w-full h-full object-cover object-center border-[2px] border-borderPrimary"
                      src="images/carpet-img.jpg"
                      alt=""
                    />
                  </div>
                  <Icon
                    className="primaryHoverWhite w-[24px] h-[24px] p-[6px] rounded-full bg-white !absolute z-[2] top-[-8px] right-[-8px] cursor-pointer before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:rounded-full before:bg-primaryColor before:opacity-10 hover:before:opacity-100"
                    name="closeBold"
                  />
                </div>
                <div className="w-[98px] h-[98px] relative mx-[10px] mb-[20px]">
                  <div className="w-full h-full overflow-hidden rounded-[16px]">
                    <img
                      className="w-full h-full object-cover object-center border-[2px] border-borderPrimary"
                      src="images/carpet-img.jpg"
                      alt=""
                    />
                  </div>
                  <Icon
                    className="primaryHoverWhite w-[24px] h-[24px] p-[6px] rounded-full bg-white !absolute z-[2] top-[-8px] right-[-8px] cursor-pointer before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:rounded-full before:bg-primaryColor before:opacity-10 hover:before:opacity-100"
                    name="closeBold"
                  />
                </div>
                <div className="w-[98px] h-[98px] relative mx-[10px] mb-[20px]">
                  <div className="w-full h-full overflow-hidden rounded-[16px]">
                    <img
                      className="w-full h-full object-cover object-center border-[2px] border-borderPrimary"
                      src="images/carpet-img.jpg"
                      alt=""
                    />
                  </div>
                  <Icon
                    className="primaryHoverWhite w-[24px] h-[24px] p-[6px] rounded-full bg-white !absolute z-[2] top-[-8px] right-[-8px] cursor-pointer before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:rounded-full before:bg-primaryColor before:opacity-10 hover:before:opacity-100"
                    name="closeBold"
                  />
                </div>
                <div className="w-[98px] h-[98px] relative mx-[10px] mb-[20px]">
                  <div className="w-full h-full overflow-hidden rounded-[16px]">
                    <img
                      className="w-full h-full object-cover object-center border-[2px] border-borderPrimary"
                      src="images/carpet-img.jpg"
                      alt=""
                    />
                  </div>
                  <Icon
                    className="primaryHoverWhite w-[24px] h-[24px] p-[6px] rounded-full bg-white !absolute z-[2] top-[-8px] right-[-8px] cursor-pointer before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:rounded-full before:bg-primaryColor before:opacity-10 hover:before:opacity-100"
                    name="closeBold"
                  />
                </div>
                <div className="w-[98px] h-[98px] relative mx-[10px] mb-[20px]">
                  <div className="w-full h-full overflow-hidden rounded-[16px]">
                    <img
                      className="w-full h-full object-cover object-center border-[2px] border-borderPrimary"
                      src="images/carpet-img.jpg"
                      alt=""
                    />
                  </div>
                  <Icon
                    className="primaryHoverWhite w-[24px] h-[24px] p-[6px] rounded-full bg-white !absolute z-[2] top-[-8px] right-[-8px] cursor-pointer before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:rounded-full before:bg-primaryColor before:opacity-10 hover:before:opacity-100"
                    name="closeBold"
                  />
                </div>
                <div className="w-[98px] h-[98px] relative mx-[10px] mb-[20px]">
                  <div className="w-full h-full overflow-hidden rounded-[16px]">
                    <img
                      className="w-full h-full object-cover object-center border-[2px] border-borderPrimary"
                      src="images/carpet-img.jpg"
                      alt=""
                    />
                  </div>
                  <Icon
                    className="primaryHoverWhite w-[24px] h-[24px] p-[6px] rounded-full bg-white !absolute z-[2] top-[-8px] right-[-8px] cursor-pointer before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:rounded-full before:bg-primaryColor before:opacity-10 hover:before:opacity-100"
                    name="closeBold"
                  />
                </div>
                <div className="w-[98px] h-[98px] relative mx-[10px] mb-[20px]">
                  <div className="w-full h-full overflow-hidden rounded-[16px]">
                    <img
                      className="w-full h-full object-cover object-center border-[2px] border-borderPrimary"
                      src="images/carpet-img.jpg"
                      alt=""
                    />
                  </div>
                  <Icon
                    className="primaryHoverWhite w-[24px] h-[24px] p-[6px] rounded-full bg-white !absolute z-[2] top-[-8px] right-[-8px] cursor-pointer before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:rounded-full before:bg-primaryColor before:opacity-10 hover:before:opacity-100"
                    name="closeBold"
                  />
                </div>
                <div className="w-[98px] h-[98px] relative mx-[10px] mb-[20px]">
                  <div className="w-full h-full overflow-hidden rounded-[16px]">
                    <img
                      className="w-full h-full object-cover object-center border-[2px] border-borderPrimary"
                      src="images/carpet-img.jpg"
                      alt=""
                    />
                  </div>
                  <Icon
                    className="primaryHoverWhite w-[24px] h-[24px] p-[6px] rounded-full bg-white !absolute z-[2] top-[-8px] right-[-8px] cursor-pointer before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:rounded-full before:bg-primaryColor before:opacity-10 hover:before:opacity-100"
                    name="closeBold"
                  />
                </div>
                <div className="w-[98px] h-[98px] relative mx-[10px] mb-[20px]">
                  <div className="w-full h-full overflow-hidden rounded-[16px] border-[2px] border-dashed border-borderPrimary">
                    <input
                      className="absolute top-0 left-0 w-full h-full z-[4] opacity-0"
                      type="file"
                    />
                    <Icon
                      className="w-[30px] h-[30px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
                      name="uploadSimple"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* image documents upload modal end */}

          {/* Pricing Table modal */}
          <div className="hidden">
            <div className="iconHeader mb-[30px]">
              <div className="flex flex-wrap items-center justify-center">
                <Icon
                  className="w-[50px] h-[50px] p-[10px] relative before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:rounded-full before:bg-primaryColor before:opacity-10"
                  name="pricing"
                />
                <h3 className="w-full text-[18px] text-textDark font-AcuminPro__Semibold text-center mt-[10px]">
                  Pricing Table
                </h3>
              </div>
            </div>
            <div className="flex flex-wrap mx-[-12px]">
              <div className="w-1/2 px-[12px]">
                <InputField
                  fieldBGClassName="bg-bgWhiteCarpet"
                  fieldWrapperClassName="carperFieldBox"
                  placeholder="Price A Roll"
                  label="Price A Roll"
                />
              </div>
              <div className="w-1/2 px-[12px]">
                <InputField
                  fieldBGClassName="bg-bgWhiteCarpet"
                  fieldWrapperClassName="carperFieldBox"
                  placeholder="Price B Cut"
                  label="Price B Cut"
                />
              </div>
            </div>
            <Icon
              className="w-[44px] h-[36px] mb-[35px] cursor-pointer p-[5px] rounded-[14px] border-[2px] border-borderPrimary hover:bg-borderPrimary"
              name="plus"
            />
            <div className="flex flex-wrap mx-[-12px]">
              <div className="w-1/2 px-[12px]">
                <InputField
                  fieldBGClassName="bg-bgWhiteCarpet"
                  fieldWrapperClassName="carperFieldBox"
                  placeholder="Special"
                  label="Special"
                />
              </div>
              <div className="w-1/2 px-[12px]">
                <InputField
                  fieldBGClassName="bg-bgWhiteCarpet"
                  fieldWrapperClassName="carperFieldBox"
                  placeholder="MSR List Price"
                  label="MSR List Price"
                />
              </div>
              <div className="w-1/2 px-[12px]">
                <InputField
                  fieldBGClassName="bg-bgWhiteCarpet"
                  fieldWrapperClassName="carperFieldBox"
                  placeholder="Cost"
                  label="Cost"
                />
              </div>
              <div className="w-1/2 px-[12px]">
                <InputField
                  fieldBGClassName="bg-bgWhiteCarpet"
                  fieldWrapperClassName="carperFieldBox"
                  placeholder="Margin"
                  label="Margin"
                />
              </div>
              <div className="w-1/2 px-[12px]">
                <InputField
                  fieldBGClassName="bg-bgWhiteCarpet"
                  fieldWrapperClassName="carperFieldBox"
                  placeholder="Calculated Price"
                  label="Calculated Price"
                />
              </div>
            </div>
          </div>
          {/* Pricing Table modal end */}

          {/* Promotion modal */}
          <div className="flex-wrap mx-[-12px] hidden">
            <div className="w-1/2 px-[12px]">
              <InputField
                fieldBGClassName="bg-bgWhiteCarpet"
                fieldWrapperClassName="carperFieldBox"
                placeholder="Being Date"
                label="Being Date"
                icon="calendar"
              />
            </div>
            <div className="w-1/2 px-[12px]">
              <InputField
                fieldBGClassName="bg-bgWhiteCarpet"
                fieldWrapperClassName="carperFieldBox"
                placeholder="End Date"
                label="End Date"
                icon="calendar"
              />
            </div>
            <div className="w-1/2 px-[12px]">
              <InputField
                fieldBGClassName="bg-bgWhiteCarpet"
                fieldWrapperClassName="carperFieldBox"
                placeholder="Unit Cost"
                label="Unit Cost"
              />
            </div>
            <div className="w-1/2 px-[12px]">
              <InputField
                fieldBGClassName="bg-bgWhiteCarpet"
                fieldWrapperClassName="carperFieldBox"
                placeholder="Roll Cost"
                label="Roll Cost"
              />
            </div>
            <div className="w-1/2 px-[12px]">
              <InputField
                fieldBGClassName="bg-bgWhiteCarpet"
                fieldWrapperClassName="carperFieldBox"
                placeholder="Qty Cost"
                label="Qty Cost"
              />
            </div>
          </div>
          {/* Promotion modal end */}
          
          <div className="">
            <SelectField
              name="product_type"
              fieldWrapperClassName="carperFieldBox"
              label="Starting Account"
              options={[
                { value: "1", label: "Product Type 1" },
                { value: "2", label: "Product Type 2" },
                { value: "3", label: "Product Type 3" },
                { value: "4", label: "Product Type 4" },
                { value: "5", label: "Product Type 5" },
                { value: "6", label: "Product Type 6" },
                { value: "7", label: "Product Type 7" },
                { value: "8", label: "Product Type 8" },
              ]}
            />
            <SelectField
              name="product_type"
              fieldWrapperClassName="carperFieldBox"
              label="Starting Account"
              options={[
                { value: "1", label: "Product Type 1" },
                { value: "2", label: "Product Type 2" },
                { value: "3", label: "Product Type 3" },
                { value: "4", label: "Product Type 4" },
                { value: "5", label: "Product Type 5" },
                { value: "6", label: "Product Type 6" },
                { value: "7", label: "Product Type 7" },
                { value: "8", label: "Product Type 8" },
              ]}
            />
            <SelectField
              name="product_type"
              fieldWrapperClassName="carperFieldBox"
              label="Starting Account"
              options={[
                { value: "1", label: "Product Type 1" },
                { value: "2", label: "Product Type 2" },
                { value: "3", label: "Product Type 3" },
                { value: "4", label: "Product Type 4" },
                { value: "5", label: "Product Type 5" },
                { value: "6", label: "Product Type 6" },
                { value: "7", label: "Product Type 7" },
                { value: "8", label: "Product Type 8" },
              ]}
            />
            <SelectField
              name="product_type"
              fieldWrapperClassName="carperFieldBox"
              label="Starting Account"
              options={[
                { value: "1", label: "Product Type 1" },
                { value: "2", label: "Product Type 2" },
                { value: "3", label: "Product Type 3" },
                { value: "4", label: "Product Type 4" },
                { value: "5", label: "Product Type 5" },
                { value: "6", label: "Product Type 6" },
                { value: "7", label: "Product Type 7" },
                { value: "8", label: "Product Type 8" },
              ]}
            />
            <SelectField
              name="product_type"
              fieldWrapperClassName="carperFieldBox"
              label="Starting Account"
              options={[
                { value: "1", label: "Product Type 1" },
                { value: "2", label: "Product Type 2" },
                { value: "3", label: "Product Type 3" },
                { value: "4", label: "Product Type 4" },
                { value: "5", label: "Product Type 5" },
                { value: "6", label: "Product Type 6" },
                { value: "7", label: "Product Type 7" },
                { value: "8", label: "Product Type 8" },
              ]}
            />
            <SelectField
              name="product_type"
              fieldWrapperClassName="carperFieldBox"
              label="Starting Account"
              options={[
                { value: "1", label: "Product Type 1" },
                { value: "2", label: "Product Type 2" },
                { value: "3", label: "Product Type 3" },
                { value: "4", label: "Product Type 4" },
                { value: "5", label: "Product Type 5" },
                { value: "6", label: "Product Type 6" },
                { value: "7", label: "Product Type 7" },
                { value: "8", label: "Product Type 8" },
              ]}
            />
          </div>
          
        </Modal>
      )}
    </>
  );
};

export default StaticModal;
