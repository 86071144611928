const PlusCircle = () => {
  return (
    <>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="">
        <path d="M15.9541 2C13.1648 2 10.4752 2.80116 8.18403 4.40349C5.89286 5.90567 4.09976 8.10886 3.00399 10.7126C2.00782 13.2163 1.70897 16.0203 2.30667 18.7243C2.80475 21.4282 4.19938 23.9318 6.09209 25.9347C8.08442 27.9376 10.5748 29.2395 13.2645 29.7402C15.9541 30.241 18.7434 30.0407 21.3334 28.9391C23.9234 27.8375 26.0153 26.0349 27.6092 23.7315C29.1035 21.4282 30 18.7243 30 15.9202C30 12.2148 28.5058 8.60959 25.9157 6.00581C23.2261 3.50218 19.6399 2 15.9541 2ZM15.9541 27.8375C13.6629 27.8375 11.3718 27.1365 9.37943 25.8346C7.48672 24.5327 5.89286 22.6299 4.99631 20.5269C4.09976 18.3237 3.90053 16.0203 4.299 13.717C4.79708 11.4137 5.99247 9.31061 7.58633 7.60814C9.27981 5.90567 11.3718 4.80407 13.6629 4.40349C15.9541 3.90276 18.3449 4.2032 20.4368 5.1045C22.5288 6.00581 24.4215 7.50799 25.7165 9.5109C27.0115 11.4137 27.7088 13.717 27.7088 16.1205C27.7088 19.225 26.4138 22.3295 24.2223 24.5327C22.1303 26.6357 19.0422 27.8375 15.9541 27.8375ZM22.4292 16.0203C22.4292 16.3208 22.3295 16.6212 22.1303 16.8215C21.9311 17.0218 21.6322 17.1219 21.3334 17.1219H17.0499V21.4282C17.0499 21.7286 16.9503 22.0291 16.751 22.2293C16.5518 22.4296 16.253 22.5298 15.9541 22.5298C15.6553 22.5298 15.3564 22.4296 15.1572 22.2293C14.9579 22.0291 14.8583 21.7286 14.8583 21.4282V17.1219H10.5748C10.276 17.1219 9.97713 17.0218 9.77789 16.8215C9.57866 16.6212 9.47904 16.3208 9.47904 16.0203C9.47904 15.7199 9.57866 15.4195 9.77789 15.2192C9.97713 15.0189 10.276 14.9187 10.5748 14.9187H14.8583V10.6125C14.8583 10.3121 14.9579 10.0116 15.1572 9.81133C15.3564 9.61104 15.6553 9.5109 15.9541 9.5109C16.253 9.5109 16.5518 9.61104 16.751 9.81133C16.9503 10.0116 17.0499 10.3121 17.0499 10.6125V14.9187H21.3334C21.6322 14.9187 21.9311 15.0189 22.1303 15.2192C22.2299 15.4195 22.4292 15.7199 22.4292 16.0203Z" />
      </svg>
    </>
  );
};
export default PlusCircle;
