// ** Packages **
import { AxiosRequestConfig } from "axios";

// ** Hook **
import { useAxiosPost } from "hooks/useAxios";

const GLOBAL_SEARCH_API_BASE_PATH = "/global-search";

export const useGetGlobalSearchAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const getGlobalSearchAPI = async ({
    data,
    config = {},
  }: {
    data: object;
    config?: AxiosRequestConfig<object>;
  }) => {
    return callApi(`${GLOBAL_SEARCH_API_BASE_PATH}/list`, data, config);
  };

  return { getGlobalSearchAPI, isLoading, isError, isSuccess };
};
