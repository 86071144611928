// ** Packages **
import classNames from "classnames";
import { useEffect } from "react";
import { createPortal } from "react-dom";

// ** Components **
import Button from "components/Button";
import Icon from "components/Icon";
import TippyDropdown from "components/TippyDropdown";
import { IconTypes } from "components/Icon/types";

// ** CSS **
// import "./style/modal.css";

type Props = {
  title?: string;
  visible: boolean;
  children: React.ReactNode;
  onClose?: () => void;
  onCancel?: () => void;
  onClear?: () => void;
  onSubmit?: () => void;
  clearButtonText?: string;
  submitButtonText?: string;
  submitButtonDisabled?: boolean;
  cancelButtonText?: string;
  submitLoading?: boolean;
  width?: number;
  modalWrapperClass?: string;
  hideCancelButton?: boolean;
  hideSubmitButton?: boolean;
  hideCrossIcon?: boolean;
  hideFooter?: boolean;
  modalRef?: React.RefObject<HTMLDivElement>;
  createPortalEnable?: boolean;
  dropDownSaveButtonClick?: () => void;
  dropDownSaveButtonText?: string;
  dropDownSaveButtonLoading?: boolean;
  showClearButton?: boolean;
  iconHeaderWrapperClass?: string;
  iconHeaderIconClass?: string;
  iconHeaderTitleClass?: string;
  iconHeaderTitle?: string;
  iconHeaderIcon?: IconTypes;
};

const Modal = (props: Props) => {
  const {
    visible = false,
    children,
    title = "",
    onSubmit,
    onCancel,
    onClose,
    onClear,
    cancelButtonText = "Cancel",
    submitButtonText = "Save",
    clearButtonText = "Clear",
    width = 500,
    submitLoading = false,
    submitButtonDisabled = false,
    modalWrapperClass = "",
    hideCancelButton = true,
    hideSubmitButton = false,
    hideCrossIcon = true,
    hideFooter,
    modalRef,
    createPortalEnable = true,
    dropDownSaveButtonClick,
    dropDownSaveButtonText = "Save and ??",
    dropDownSaveButtonLoading = false,
    showClearButton = false,
    iconHeaderWrapperClass = "",
    iconHeaderIcon = "setting",
    iconHeaderIconClass = "",
    iconHeaderTitleClass = "",
    iconHeaderTitle = "",
  } = props;
  useEffect(() => {
    const classExist = document.body.classList.contains("active");
    const modalOpenTarget = document.getElementsByTagName("html");
    if (visible && !classExist) {
      modalOpenTarget[0]?.classList.add("modal__open");
    }
    return () => {
      modalOpenTarget[0]?.classList.remove("modal__open");
    };
  });

  const renderModal = () => {
    return (
      <div
        ref={modalRef}
        className={classNames(
          `modal__wrapper__carpet ${!visible ? "hidden" : ""}`,
          {
            [modalWrapperClass]: !!modalWrapperClass,
          }
        )}
      >
        <div className="modal__overlay__carpet" />
        <div
          className="modal__content__wrapper bg-bgWhiteCarpet"
          style={{ width: `${width}px` }}
        >
          <div className="modal__header">
            <h3 className="title">{title}</h3>
            {hideCrossIcon ? (
              <button className="modal__close__btn" onClick={onClose}>
                <Icon name="closeBold" />
              </button>
            ) : null}
          </div>
          <div className="modal__body fancyScroll__carpet">
            {iconHeaderIcon && iconHeaderTitle ? (
              <div
                className={`iconHeader mb-[30px] mt-[-20px] px-[60px] sm:px-[15px] ${iconHeaderWrapperClass}`}
              >
                {iconHeaderIcon && (
                  <Icon className={iconHeaderIconClass} name={iconHeaderIcon} />
                )}
                {iconHeaderTitle && (
                  <h3 className={`iconHeader__title ${iconHeaderTitleClass}`}>
                    {iconHeaderTitle}
                  </h3>
                )}
              </div>
            ) : (
              <></>
            )}
            <div className="modalScroll__wrapper fancyScroll">{children}</div>
          </div>
          {!hideFooter && (
            <div className="modal__footer">
              <div className="modal__footer__right flex items-center flex-end sm:w-full sm:justify-between">
                {showClearButton && (
                  <Button className="primary__ghost__Btn" onClick={onClear}>
                    {clearButtonText}
                  </Button>
                )}
                {hideCancelButton ? (
                  <Button
                    className={`primary__ghost__Btn ${
                      showClearButton && "ml-[10px]"
                    }`}
                    onClick={onCancel}
                  >
                    {cancelButtonText}
                  </Button>
                ) : null}
                {!hideSubmitButton ? (
                  <Button
                    className={`primaryBtn ml-[10px] ${
                      dropDownSaveButtonClick && dropDownSaveButtonText
                        ? "before:!rounded-r-[0px]"
                        : ""
                    } ${submitButtonDisabled ? "disabled" : ""}`}
                    onClick={onSubmit}
                    disabled={submitButtonDisabled}
                    loading={submitLoading}
                  >
                    {submitButtonText}
                  </Button>
                ) : null}
                {dropDownSaveButtonClick && dropDownSaveButtonText && (
                  <div
                    className={`${dropDownSaveButtonLoading ? "disabled" : ""}`}
                  >
                    <TippyDropdown
                      className="tippy__dropdown"
                      placement="bottom-end"
                      interactive
                      hideOnClick
                      content={({ close }) => (
                        <ul className="tippy__dropdown__ul hideScrollbar__SD">
                          <div className="item">
                            <div
                              className="item__link"
                              onClick={() => {
                                close();
                                dropDownSaveButtonClick();
                              }}
                            >
                              <span className="item__text">
                                {dropDownSaveButtonText}
                              </span>
                            </div>
                          </div>
                        </ul>
                      )}
                    >
                      <button
                        type="button"
                        className="w-[32px] h-[32px] bg-primaryColorSD rounded-r-[5px] p-[7px] ml-[1px] duration-300 shadow-raiseShadow hover:bg-primaryColorSD700"
                      >
                        <Icon
                          className="whiteIcon"
                          fill="#ffffff"
                          name="arrowDownBold"
                        />
                      </button>
                    </TippyDropdown>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return visible
    ? createPortalEnable
      ? createPortal(renderModal(), document.body)
      : renderModal()
    : null;
};

export default Modal;
