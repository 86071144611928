// ** CSS **
// import "./style/smallLoader.css";

type Props = { smallLoaderWrapperClassName?: string, smallLoaderClassName?: string };

const Spinner = (props: Props) => {
  const { smallLoaderWrapperClassName = "", smallLoaderClassName = "" } = props;
  return (
    <div className={`smallLoaderWrapper ${smallLoaderWrapperClassName}`}>
      <span className={`smallLoader ${smallLoaderClassName}`} />
    </div>
  );
};

export default Spinner;
