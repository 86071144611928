// ** Packages **
import Inputmask from "inputmask";
import { useEffect, useRef } from "react";
import { Controller } from "react-hook-form";

// ** CSS **
// import "./style/input.css";

// ** components **
import HelperText from "components/FormField/components/common/HelperText";
import Label from "components/FormField/components/common/Label";
import Icon from "components/Icon";

// ** Types **
import { PercentageFieldPropsTypes } from "components/FormField/types/formField.types";

const PercentageField = <TFormValues extends Record<string, unknown>>(
  fieldProps: PercentageFieldPropsTypes<TFormValues>
) => {
  const {
    icon,
    name,
    value,
    label,
    errors,
    control,
    onChange,
    errorClass = "",
    className = "",
    iconClass = "",
    labelClass = "",
    maxLength = 100,
    placeholder = "",
    required = false,
    disabled = false,
    wrapperClass = "",
    fieldWrapperClassName = "",
    fieldBGClassName = "",
    isIconRight = false,
    allow_decimal = false,
  } = fieldProps;

  // ** hooks **
  const percentageRef = useRef(null);

  useEffect(() => {
    const im = new Inputmask("percentage", {
      rightAlign: false,
      allowMinus: false,
      showMaskOnHover: false,
      ...(allow_decimal && {
        digits: 2,
        radixPoint: ".",
        digitsOptional: false,
      }),
    });

    if (percentageRef.current) {
      im.mask(percentageRef.current);
    }
  }, [allow_decimal]);

  return (
    <div
      className={`field__wrapper ${
        errors?.message ? "field__has__error" : ""
      } ${disabled ? "disable" : ""} ${fieldWrapperClassName}`}
    >
      {label && (
        <Label label={label} required={required} labelClass={labelClass} />
      )}
      <div
        className={`field__inner__wrapper ${icon ? "field__has__icon" : ""} ${
          isIconRight ? "icon__right" : ""
        } ${wrapperClass}`}
      >
        {control && name ? (
          <Controller
            name={name}
            control={control}
            render={({
              field: {
                onChange: innerOnChange,
                value: innerValue,
                name: innerName,
              },
            }) => {
              return (
                <input
                  type="text"
                  id="percentage"
                  name={innerName}
                  autoComplete="off"
                  inputMode="numeric"
                  maxLength={maxLength}
                  ref={percentageRef}
                  onBlur={innerOnChange}
                  onChange={innerOnChange}
                  placeholder={placeholder}
                  className={`input__carpet ${className}`}
                  value={(innerValue as string) || ""}
                />
              );
            }}
          />
        ) : (
          <input
            type="text"
            name={name}
            id="percentage"
            autoComplete="off"
            inputMode="numeric"
            maxLength={maxLength}
            ref={percentageRef}
            placeholder={placeholder}
            className={`input__carpet ${className}`}
            {...(onChange && { onChange })}
            value={(value as string) || ""}
            {...(onChange && { onBlur: onChange })}
          />
        )}
        {label && (
          <Label
            id={name}
            label={label}
            labelClass={labelClass}
            required={required}
          />
        )}
        <span className={`bgWrapper ${fieldBGClassName}`} />
        {icon && <Icon name={icon} className={iconClass || ""} />}
      </div>
      {errors?.message && (
        <HelperText
          helperText={errors?.message || ""}
          helperTextClass={`error__text ${errorClass}`}
        />
      )}
    </div>
  );
};

export default PercentageField;
