const Search = () => {
  return (
    <>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="">
        <path d="M29.9029 28.1004L23.3972 21.4019C25.3392 19.1024 26.2131 16.1031 26.0189 13.0038C25.8247 10.0045 24.4653 7.10512 22.3291 5.10557C20.0958 3.00604 17.1827 1.90629 14.2697 2.00626C11.3567 2.10624 8.44371 3.30597 6.4046 5.50548C4.36549 7.70499 3.10318 10.6043 3.00608 13.6037C2.90898 16.603 3.97709 19.6023 6.0162 21.9018C8.0553 24.2013 10.7741 25.501 13.6871 25.701C16.6001 25.9009 19.5132 24.9011 21.8436 23.0016L28.3493 29.7001C28.4464 29.8 28.5435 29.9 28.6406 29.9C28.7377 29.9 28.9319 30 29.029 30C29.1261 30 29.3203 30 29.4174 29.9C29.5145 29.8 29.6116 29.8 29.7087 29.7001C29.8058 29.6001 29.9029 29.5001 29.9029 29.4001C30 29.3002 30 29.1002 30 29.0002C30 28.9002 30 28.7003 29.9029 28.6003C29.8058 28.5003 30 28.2004 29.9029 28.1004ZM5.14229 13.8036C5.14229 11.904 5.72489 10.0045 6.6959 8.40483C7.764 6.80519 9.22051 5.60546 10.8712 4.80564C12.619 4.10579 14.4639 3.90584 16.3088 4.20577C18.1537 4.60568 19.8045 5.50548 21.1639 6.80519C22.5233 8.20488 23.3972 9.9045 23.6885 11.8041C24.0769 13.7036 23.8827 15.6032 23.1059 17.4028C22.4262 19.2024 21.1639 20.7021 19.6103 21.7019C18.0566 22.8016 16.2117 23.3015 14.3668 23.3015C11.8422 23.3015 9.51181 22.3017 7.764 20.5021C6.1133 18.9025 5.14229 16.403 5.14229 13.8036Z" />
      </svg>
    </>
  );
};
export default Search;
