// ** Packages **
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// ** Redux **
import { RootStateType } from "redux/store";

// ** Types **
import { DynamicObjectType } from "types";

export type ProductSliceType = {
  list?: DynamicObjectType[];
  record?: DynamicObjectType | null;
};

const initialState: ProductSliceType = {
  list: [],
  record: null,
};

const slice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProductList(
      state: ProductSliceType,
      action: PayloadAction<ProductSliceType>
    ) {
      state.list = action.payload.list;
    },
    setProductRecord(
      state: ProductSliceType,
      action: PayloadAction<ProductSliceType>
    ) {
      state.record = action.payload.record;
    },
  },
});

export const { reducer } = slice;

export const { setProductList, setProductRecord } = slice.actions;

export const getProductList = (state: RootStateType) => state.product.list;

export const getProductRecord = (state: RootStateType) => state.product.record;

export default slice;
