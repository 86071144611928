// ** Packages ** //
import classNames from "classnames";
import { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

// ** Constant **
import { SettingMenuItemType } from "../constants";

// ** Components **
import Icon from "components/Icon";
import SubMenu from "modules/Setting/components/SettingSubMenu";

// ** Redux **
import { setSideBarToggle } from "redux/slices/generalSettingSlice";

// ** Types **
type Props = {
  menu: SettingMenuItemType;
  setCurrentPath: Dispatch<React.SetStateAction<string>>;
  currentPath: string;
};

const SettingMenuItem = (props: Props) => {
  const { menu, setCurrentPath, currentPath } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathString = location.pathname;
  // ** state **
  const [isActive, setIsActive] = useState(pathString.includes(menu.path));
  const [menuIsOpen, setMenuIsOpen] = useState(pathString.includes(menu.path));
  const hasSubmenu = menu.subItems && menu.subItems.length > 0 && menu.subItems;

  useEffect(() => {
    setIsActive(pathString.includes(menu.path));
    setMenuIsOpen(pathString.includes(menu.path));
  }, [location.pathname, menu.path, currentPath]);

  useEffect(() => {
    if (currentPath) {
      if (currentPath === menu.path) {
        setMenuIsOpen(true);
      } else {
        setMenuIsOpen(false);
      }
    }
  }, [currentPath]);

  const handleMenu = (e: React.MouseEvent<any>) => {
    e.preventDefault();
    setMenuIsOpen(!menuIsOpen);
    setCurrentPath(menu.path);
    if (!menu.subItems?.length) {
      navigate(menu.path);
    }
  };

  return (
    <div className="setting__menu__item">
      {menu.subItems?.length ? (
        <div
          className={classNames("setting__menu__link", {
            menu__is__open: menuIsOpen,
            highlight: isActive,
          })}
          onClick={handleMenu}
        >
          <span className="setting__menu__text">{menu.text}</span>
          {hasSubmenu && (
            <Icon
              className="down__arrow"
              name={menu.icon}
              onClick={handleMenu}
            />
          )}
        </div>
      ) : (
        <Link
          className={classNames("setting__menu__link", {
            menu__is__open: menuIsOpen,
            highlight: isActive,
          })}
          to={menu.path}
          onClick={(e) => {
            handleMenu(e);
            dispatch(setSideBarToggle({ isSideBarOpen: false }));
          }}
        >
          <span className="setting__menu__text">{menu.text}</span>
          {hasSubmenu && (
            <Icon
              className="down__arrow"
              name={menu.icon}
              onClick={handleMenu}
            />
          )}
        </Link>
      )}
      {hasSubmenu && menuIsOpen && <SubMenu subItems={hasSubmenu} />}
    </div>
  );
};

export default SettingMenuItem;
