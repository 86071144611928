// ** packages **
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// ** redux **
import {
  getAuth,
  setAuthInitialized,
  setCredentials,
  setUserData,
} from "redux/slices/authSlice";

// ** Service **
import { useLoggedUserAPI } from "../services";
import { setCurrentUserPermissions } from "redux/slices/permissionSlice";

const useAuthGuard = () => {
  // ** Hooks **
  const dispatch = useDispatch();
  const { isAuthenticated, isAuthInitialized, isDataLoading } =
    useSelector(getAuth);

  // ** APIS **
  const { loggedUserAPI, isLoading } = useLoggedUserAPI();

  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = async () => {
    if (!isAuthenticated && !isAuthInitialized) {
      const userInfo = localStorage.getItem("active-user");
      if (userInfo) {
        const { data, error } = await loggedUserAPI({});
        if (!error && data) {
          if (data?.verified) {
            dispatch(setCurrentUserPermissions(data.permissions));
            dispatch(setCredentials({ user: data }));
            dispatch(setUserData({ user: data }));
          }
        }
      }
      dispatch(setAuthInitialized());
    }
  };

  return {
    isLoading,
    isAuthenticated,
    isAuthInitialized,
    isDataLoading,
  };
};

export default useAuthGuard;
