// ** Packages **
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "redux/store";

// ** css **
import "./index.css";

// ** Custom Components **
import App from "App";
import { setupAxios } from "base-axios";
import Toast from "components/Toast";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

setupAxios(store);

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Toast />
      <App />
    </PersistGate>
  </Provider>
);
