export enum FIELD_TYPE {
  URL = "url",
  TEXT = "text",
  PHONE = "phone",
  EMAIL = "email",
  ADDRESS = "address",
  SELECT = "select",
  LARGE_TEXT = "textarea",
  NUMBER = "number",
  CURRENCY = "currency",
  PERCENTAGE = "percentage",
  DATE = "datepicker",
  TIME = "timepicker",
  DATE_TIME = "dateTimePicker",
  DATE_RANGE = "dateRangePicker",
  MULTI_SELECT_PICK_LIST = "checkbox",
  PICK_LIST = "radio",
  ASYNC_SELECT = "asyncSelect",
  ATTACHMENT = "file",
  COLOR = "color",
  CREATABLE_ASYNC_SELECT = "creatableAsyncSelect",
  CREATABLE_SELECT = "creatableSelect",
  DROPZONE = "dropZone",
  PASSWORD = "password",
  TIME_RANGE = "timeRangePicker",
}

export enum ATTACHMENT_FILE_TYPES {
  PNG = "image/png",
  JPG = "image/jpg",
  JPEG = "image/jpeg",
  MP3 = "audio/mpeg",
  OGG = "audio/ogg",
  MPEG = "audio/mpeg",
  MP4 = "video/mp4",
  WEBM = "video/webm",
  OGG_VIDEO = "video/ogg",
  PLAIN_TEXT = "text/plain",
  PDF = "application/pdf",
  XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  XLS = "application/vnd.ms-excel",
  XLSM = "application/vnd.ms-excel.sheet.macroEnabled.12",
  DOC = "application/msword",
  DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
}
