// ** Packages **
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Option } from "components/FormField/types/formField.types";

// ** Redux **
import { RootStateType } from "redux/store";

// ** Types **
import { DynamicObjectType } from "types";

export type CustomerSliceType = {
  list?: DynamicObjectType[];
  record?: DynamicObjectType | null;
  customerObj?: Option | null;
};

const initialState: CustomerSliceType = {
  list: [],
  record: null,
  customerObj: null,
};

const slice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setCustomerList(
      state: CustomerSliceType,
      action: PayloadAction<CustomerSliceType>
    ) {
      state.list = action.payload.list;
    },
    setCustomerRecord(
      state: CustomerSliceType,
      action: PayloadAction<CustomerSliceType>
    ) {
      state.record = action.payload.record;
    },
    setConvertLeadOrderCustomerId(
      state: CustomerSliceType,
      action: PayloadAction<CustomerSliceType>
    ) {
      state.customerObj = action.payload.customerObj;
    },
  },
});

export const { reducer } = slice;

export const {
  setCustomerList,
  setCustomerRecord,
  setConvertLeadOrderCustomerId,
} = slice.actions;

export const getCustomerList = (state: RootStateType) => state.customer.list;

export const getCustomerRecord = (state: RootStateType) =>
  state.customer.record;

export const getConvertLeadOrderCustomerId = (state: RootStateType) =>
  state.customer.customerObj;

export default slice;
