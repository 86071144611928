// ** package **
import { Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";

// ** component **
import IconButton from "components/Button/IconButton";
import PageLoader from "components/Loaders/PageLoader";
import SettingSidebar from "./SettingSidebar";

// ** Redux **
import {
  getIsSideBarToggle,
  setSideBarToggle,
} from "redux/slices/generalSettingSlice";

type Props = { children?: React.ReactNode };

const SettingLayout = (props: Props) => {
  const { children } = props;
  const dispatch = useDispatch();

  const isSideBarOpen = useSelector(getIsSideBarToggle);

  return (
    <div
      className={`setting__wrapper flex flex-wrap ${
        isSideBarOpen ? "sidebarOpen" : ""
      }`}
    >
      <SettingSidebar />
      <span className="hidden">
        <IconButton
          className=""
          onClick={() => {
            dispatch(setSideBarToggle({ isSideBarOpen: !isSideBarOpen }));
          }}
          icon={isSideBarOpen ? "caretLeftBold" : "caretRightBold"}
        />
      </span>
      <div className="setting__panel__wrapper w-[calc(100%_-_316px)] ml-[30px] h-[calc(100vh_-_132px)] rounded-[10px] bg-bgWhiteSD 3xl:ml-[20px] 3xl:w-[calc(100%_-_290px)] lg:h-[calc(100dvh_-_87px)] md:h-[calc(100dvh_-_125px)]">
        <Outlet />
        <Suspense fallback={<PageLoader />}>{children}</Suspense>
      </div>
    </div>
  );
};

export default SettingLayout;
