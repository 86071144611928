// ** Component **
import Icon from "components/Icon";

// ** Type **
import { LabelPropsType } from "components/FormField/types/label.type";

const Label = (props: LabelPropsType) => {
  const {
    id = "",
    labelIcon,
    label = "",
    labelClass = "",
    textClassName = "",
    required = false,
  } = props;
  return (
    <label htmlFor={id} className={`label__carpet ${labelClass}`}>
      {labelIcon ? <Icon name={labelIcon} /> : null}
      <span className={`label__text ${textClassName}`}>{label && label !== "" ? label : ""}</span>
      {required && label !== "" ? (
        <span className="required__sign text-dangerCarpet inline-block ml-[3px]">*</span>
      ) : (
        ""
      )}
    </label>
  );
};

export default Label;
