// ** CSS **
// import "./style/pageLoader.css";

type Props = { pageLoaderWrapperClassName?: string, pageLoaderClassName?: string };

const PageLoader = (props: Props) => {
  const { pageLoaderWrapperClassName = "", pageLoaderClassName = "" } = props;
  return (
    <div className={`pageLoaderWrapper ${pageLoaderWrapperClassName}`}>
      <span className={`pageLoader ${pageLoaderClassName}`} />
    </div>
  );
};

export default PageLoader;
