import { clearIndexDBStorage } from "services/indexDB.service";

export const logoutHelper = () => {
  const rememberMeData = localStorage.getItem("remember-me");
  const clientIp = localStorage.getItem("clientIp");

  localStorage.clear();
  if (clientIp) {
    localStorage.setItem("clientIp", clientIp);
  }
  if (rememberMeData) {
    localStorage.setItem("remember-me", rememberMeData);
  }
  localStorage.removeItem("active-user");
  document.cookie.split(";").forEach((cookie) => {
    document.cookie = cookie
      .replace(/^ +/, "")
      .replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`);
  });
  clearIndexDBStorage();
};
