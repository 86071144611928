// ** Packages **
import { Controller } from "react-hook-form";
import PhoneInput, { CountryData } from "react-phone-input-2";

// ** CSS **
// import "./style/input.css";
// import "./style/phoneField.css";

// ** Components **
import HelperText from "components/FormField/components/common/HelperText";
import Label from "components/FormField/components/common/Label";
// import Icon from "components/Icon";

// ** Type **
import { PhoneFieldProps } from "components/FormField/types/formField.types";
import Icon from "components/Icon";

const PhoneField = <TFormValues extends Record<string, unknown>>(
  fieldProps: PhoneFieldProps<TFormValues>
) => {
  const {
    label,
    name,
    errors,
    value,
    control,
    errorClass = "",
    labelClass = "",
    disabled = false,
    required = false,
    placeholder = "",
    wrapperClass = "",
    fieldWrapperClassName = "",
    fieldBGClassName = "",
    onChange: customOnChange,
    allowedCountry = [],
    blockedCountry = [],
    country = "us",
    masks,
    icon = "",
    iconClass = "",
    isIconRight = false,
    onIconClick,
  } = fieldProps;

  return (
    <div
      className={`field__wrapper ${
        errors?.message ? "field__has__error" : ""
      } ${disabled ? "disable" : ""} ${fieldWrapperClassName}`}
    >
      {label && (
        <Label
          id={name}
          label={label}
          required={required}
          labelClass={labelClass}
        />
      )}
      <div className={`field__inner__wrapper  ${wrapperClass} ${icon ? "field__has__icon" : ""} ${
          isIconRight ? "icon__right" : ""
        }`}>
        {control ? (
          name && (
            <Controller
              name={name}
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  onlyCountries={allowedCountry}
                  excludeCountries={blockedCountry}
                  country={country}
                  placeholder={placeholder}
                  onChange={(
                    phoneValue: string,
                    data: CountryData,
                    event: React.ChangeEvent<HTMLInputElement>,
                    formattedValue: string
                  ) => {
                    onChange(formattedValue);
                    customOnChange?.(formattedValue as any);
                  }}
                  inputClass="input__carpet"
                  value={value as any}
                  masks={masks}
                />
              )}
            />
          )
        ) : (
          <PhoneInput
            onlyCountries={allowedCountry}
            excludeCountries={blockedCountry}
            country="us"
            placeholder={placeholder}
            value={value}
            inputClass="input__carpet"
          />
        )}
        {label && (
          <Label
            id={name}
            label={label}
            required={required}
            labelClass={labelClass}
          />
        )}
        <span className={`bgWrapper ${fieldBGClassName}`} />
        {icon && (
          <Icon
            name={icon}
            className={
              `${iconClass} ${
                typeof onIconClick === "function" ? "cursor-pointer" : ""
              }` || ""
            }
            {...(onIconClick && { onClick: onIconClick })}
          />
        )}
      </div>
      {errors?.message && (
        <HelperText
          helperText={errors?.message || ""}
          helperTextClass={`error__text ${errorClass}`}
        />
      )}
    </div>
  );
};

export default PhoneField;
