// ** Packages **
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// ** Components **
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";

// ** Redux **
import { getCurrentUser } from "redux/slices/authSlice";

// ** Constant **
import { PRIVATE_NAVIGATION } from "constants/navigation.constant";

type Props = { children: React.ReactNode };

const Layout = (props: Props) => {
  const { children } = props;
  const navigate = useNavigate();
  const CurrentUser = useSelector(getCurrentUser);

  const [isSidebarCollapse, setSidebarCollapse] = useState(false);

  const sideBarToggle = () => {
    if (window.innerWidth <= 990) {
      setSidebarCollapse(!isSidebarCollapse);
      const modalOpenTarget = document.getElementsByTagName("html");
      if (!isSidebarCollapse) {
        modalOpenTarget[0]?.classList.add("mobileSidebarCollapse");
      } else {
        modalOpenTarget[0]?.removeAttribute("class");
      }
    }
  };

  const redirectToDashboard = () => {
    navigate(PRIVATE_NAVIGATION.DASHBOARD.VIEW);
  };

  return (
    <>
      <span className="overlayScreen" onClick={() => sideBarToggle()} />
      <div
        className={`mainWrapper ${isSidebarCollapse ? "sidebarCollapse" : ""}`}
      >
        <Sidebar
          sideBarToggle={sideBarToggle}
          redirectToDashboard={redirectToDashboard}
        />
        <div className="rightMainWrapper">
          <Header
            headerTitle={`Welcome ${CurrentUser?.full_name ?? ""}!`}
            isSidebarCollapse={isSidebarCollapse}
            setSidebarCollapse={setSidebarCollapse}
            sideBarToggle={sideBarToggle}
            redirectToDashboard={redirectToDashboard}
          />
          <div className="carpetMainCN__wrapper fancyScroll">{children}</div>
        </div>
      </div>
    </>
  );
};

export default Layout;
