// ** Constant **
import { PRIVATE_NAVIGATION } from "constants/navigation.constant";

// ** Type **
import { NavigationItemType } from "../types";

// ** Constant **
import { PERMISSION_MODULE } from "constants/entity.constant";

export const TOP_NAVIGATION_ITEM: NavigationItemType[] = [
  {
    path: PRIVATE_NAVIGATION.DASHBOARD.VIEW,
    icon: "setting",
    label: "DashBoard",
  },
  {
    path: PRIVATE_NAVIGATION.LEAD.VIEW,
    icon: "lead",
    label: "Leads",
    module: PERMISSION_MODULE.LEAD,
  },
  {
    path: PRIVATE_NAVIGATION.CUSTOMER.VIEW,
    icon: "users",
    label: "Customers",
    module: PERMISSION_MODULE.CUSTOMER,
  },
  {
    path: PRIVATE_NAVIGATION.PRODUCT.VIEW,
    icon: "product",
    label: "Products",
    module: PERMISSION_MODULE.PRODUCT,
  },
  {
    path: PRIVATE_NAVIGATION.ORDER.VIEW,
    icon: "order",
    label: "Orders",
    module: PERMISSION_MODULE.ORDER,
  },
  {
    path: PRIVATE_NAVIGATION.PURCHASE_ORDER.VIEW,
    icon: "order",
    label: "Purchase Orders",
    module: PERMISSION_MODULE.PURCHASE_ORDER,
  },
  {
    path: PRIVATE_NAVIGATION.CALENDAR.VIEW,
    icon: "calendar",
    label: "Calendar",
    module: PERMISSION_MODULE.CALENDAR,
  },
  {
    path: PRIVATE_NAVIGATION.SALES_COMMISSION.VIEW,
    icon: "estimate",
    label: "Sales Commission",
    module: PERMISSION_MODULE.SALES_COMMISSION,
  },
  {
    path: PRIVATE_NAVIGATION.INSTALLER_HISTORY.VIEW,
    icon: "order",
    label: "Installer History",
    module: PERMISSION_MODULE.INSTALLER_HISTORY,
  },
  // ** FUTURE FUNCTIONALITY **
  // {
  //   path: PRIVATE_NAVIGATION.ESTIMATE.VIEW,
  //   icon: "estimate",
  //   label: "Estimate",
  // },
];

export const BOTTOM_NAVIGATION_ITEM: NavigationItemType[] = [
  {
    path: PRIVATE_NAVIGATION.TEAM.VIEW,
    icon: "addUser",
    label: "Team",
    module: PERMISSION_MODULE.TEAM,
  },
  {
    path: PRIVATE_NAVIGATION.REPORT.VIEW,
    icon: "graph",
    label: "Reports",
  },
  {
    path: PRIVATE_NAVIGATION.SETTING.VIEW,
    icon: "setting",
    label: "Settings",
    module: PERMISSION_MODULE.SETTING,
  },
];

export const QUICK_ADD: NavigationItemType[] = [
  {
    path: PRIVATE_NAVIGATION.LEAD.ADD,
    icon: "lead",
    label: "Create Lead",
    module: PERMISSION_MODULE.LEAD,
  },
  {
    path: PRIVATE_NAVIGATION.CUSTOMER.ADD,
    icon: "users",
    label: "Create Customer",
    module: PERMISSION_MODULE.CUSTOMER,
  },
  {
    path: PRIVATE_NAVIGATION.PRODUCT.ADD,
    icon: "product",
    label: "Create Product",
    module: PERMISSION_MODULE.PRODUCT,
  },
  {
    path: PRIVATE_NAVIGATION.ORDER.ADD,
    icon: "order",
    label: "Create Order",
    module: PERMISSION_MODULE.ORDER,
  },
  {
    path: PRIVATE_NAVIGATION.PURCHASE_ORDER.ADD,
    icon: "order",
    label: "Create PO",
    module: PERMISSION_MODULE.PURCHASE_ORDER,
  },
  // ** FUTURE FUNCTIONALITY **
  // {
  //   path: PRIVATE_NAVIGATION.ESTIMATE.ADD,
  //   icon: "estimate",
  //   label: "Estimate",
  // },

  {
    path: PRIVATE_NAVIGATION.REPORT.VIEW,
    icon: "graph",
    label: "Create Report",
  },
];
