// ** Redux **
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootStateType } from "redux/store";

// ** Helper **
import { logoutHelper } from "modules/Auth/helper";

// ** Type **
import { DynamicObjectType } from "types";

// ** Util **
import { encodeToBase64 } from "utils";

export type AuthUserType = {
  id: number;
  username: string;
  first_name: string;
  full_name: string;
  last_name: string;
  profile_image?: string;
  role_id?: number;
  userRole?: string;
  email: string;
  phone: string;
  verified: boolean;
  mobile: string;
  role?: { id?: number; name?: string; is_system?: boolean };
  salesCommission: DynamicObjectType[];
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
};

export type AuthSliceType = {
  user?: Partial<AuthUserType | null>;
  isAuthenticated?: boolean;
  isAuthInitialized?: boolean;
  isDataLoading?: boolean;
};

const initialState: AuthSliceType = {
  user: null,
  isAuthenticated: false,
  isAuthInitialized: false,
  isDataLoading: true,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthInitialized(state: AuthSliceType) {
      state.isAuthInitialized = true;
    },
    setAuthenticated(
      state: AuthSliceType,
      action: PayloadAction<AuthSliceType>
    ) {
      state.isAuthenticated = action.payload.isAuthenticated;
    },
    setUserData(state: AuthSliceType, action: PayloadAction<AuthSliceType>) {
      const { user } = action.payload;
      state.user = { ...user, userRole: user?.role?.name };
      state.isAuthenticated = !!user?.verified;
      state.isDataLoading = false;
      if (user?.verified) {
        localStorage.setItem(
          "active-user",
          encodeToBase64(
            JSON.stringify({
              email: user.email,
              id: user.id,
              verified: user.verified,
            })
          )
        );
      } else {
        localStorage.removeItem("active-user");
      }
    },

    setCredentials(state: AuthSliceType, action: PayloadAction<AuthSliceType>) {
      const { user } = action.payload;
      if (user) {
        state.user = action.payload.user;
        state.isAuthenticated = true;
      } else {
        state.user = null;
        state.isAuthenticated = false;
      }
    },
    setUserVerified(state: AuthSliceType) {
      if (state.user) {
        state.user.verified = true;
      } else {
        state.user = { verified: true };
      }
    },
    setLogoutData(state: AuthSliceType) {
      state.user = null;
      state.isAuthenticated = false;
      state.isAuthInitialized = false;
      logoutHelper();
    },
    setIsDataLoading(state: AuthSliceType, action: PayloadAction<boolean>) {
      state.isDataLoading = action.payload;
    },
  },
});

export const { reducer } = slice;

export const {
  setCredentials,
  setLogoutData,
  setAuthenticated,
  setAuthInitialized,
  setUserData,
  setUserVerified,
  setIsDataLoading,
} = slice.actions;

export const getAuth = (state: RootStateType) => state.auth;

export const getIsAuthenticated = (state: RootStateType) =>
  state.auth.isAuthenticated;

export const getCurrentUser = (state: RootStateType) => state.auth.user;
export const getDefaultCommission = (state: RootStateType) =>
  state.auth.user?.salesCommission;
export default slice;
