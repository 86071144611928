// ** Packages **
import { Link } from "react-router-dom";

// ** Components **
import Icon from "components/Icon";

// ** Types **
import { SidebarNavItemPropsType } from "../types";

// ** Constant **
import { PRIVATE_NAVIGATION } from "constants/navigation.constant";

const SidebarNavItem = (props: SidebarNavItemPropsType) => {
  const { path, icon, label, currentPath, onClick } = props;

  const isActive =
    path === PRIVATE_NAVIGATION.DASHBOARD.VIEW
      ? currentPath === path
      : currentPath.startsWith(path.substring(0, path.length - 1));

  return (
    <div className={`menuItem ${isActive ? "active" : ""} `}>
      <Link to={path} onClick={onClick} className="menuLink !no-underline">
        <Icon name={icon} />
        <div className="menuName">{label}</div>
      </Link>
    </div>
  );
};

export default SidebarNavItem;
