// ** Component **
import { InputField } from "components/FormField";
import Icon from "components/Icon";
import ProfileToggle from "components/ProfileToggle";
// import Notification from "./Notification";
import QuickAdd from "./QuickAdd";

// ** Type **
import { HeaderPropsType } from "../types";
import { useGetGlobalSearchAPI } from "../service";
import { useState } from "react";
import _ from "lodash";
import { convertTitleCase, setUrlParams } from "utils";
import { useNavigate } from "react-router-dom";
import { PRIVATE_NAVIGATION } from "constants/navigation.constant";

const Header = (props: HeaderPropsType) => {
  const {
    headerTitle,
    setSidebarCollapse,
    isSidebarCollapse,
    sideBarToggle,
    redirectToDashboard,
  } = props;

  const navigate = useNavigate();

  const [searchData, setSearchData] = useState<
    {
      model_name: string;
      group: { name: string; id: number; model_name: string }[];
    }[]
  >([]);
  const { getGlobalSearchAPI } = useGetGlobalSearchAPI();

  const getSearchData = async (value: string) => {
    const { data } = await getGlobalSearchAPI({
      data: {
        query: {
          select: "id,model_name,name",
          searchFields: "name",
          searchText: value,
        },
      },
    });
    if (_.isArray(data?.rows) && value) {
      const groupByData = _.groupBy(data.rows, "model_name");
      const sortData = Object.entries(groupByData).map(([key, val]) => {
        const sortPolicy = val.sort((a, b) => a.name.localeCompare(b.name));
        return {
          model_name: key,
          group: sortPolicy,
        };
      });
      setSearchData(sortData);
    }
  };

  return (
    <>
      <div className="topHeader">
        <div className="afterTablet hidden md:flex md:w-full md:h-[40px]">
          <div className="inline-block relative topHeader__searchWrapper w-full pr-[12px] md:pr-[52px] lg:hidden">
            <InputField
              fieldWrapperClassName="formElementsBG__field mb-0"
              placeholder="Search"
              icon="search"
              onChange={(e) => {
                setSearchData([]);
                getSearchData(e.target.value);
              }}
            />
            {searchData?.length ? (
            <div className="searchDropdown py-[10px] absolute top-[calc(100%_+_5px)] left-0 w-full bg-bgWhiteCarpet rounded-[8px] shadow-raiseShadow z-[5]">
              <div className="searchInner__wrapper max-h-[300px] overflow-y-auto fancyScroll">
                {searchData?.map((e) => (
                  <div className="mb-[15px] last:mb-0" key={e.model_name}>
                    <p className="text-[17px] font-AcuminPro__Semibold text-textDark px-[14px] mb-[10px]">{convertTitleCase(e.model_name)}</p>
                    {e.group.map((val) => (
                      <div className="searchItem cursor-pointer py-[6px] px-[14px] group relative before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-primaryColor before:opacity-0 before:duration-300 hover:before:opacity-10" key={val.id}>
                        <span
                          className="searchValue text-[14px] font-AcuminPro__Regular text-textSecondary relative z-[2] group-hover:text-primaryColor"
                          onClick={() => {
                            const model_name = e.model_name.toUpperCase();
                            const path = PRIVATE_NAVIGATION?.[model_name]?.EDIT;
                            navigate(setUrlParams(path, val.id));
                            setSearchData([]);
                          }}
                        >
                          {val.name}
                        </span>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          </div>
          <div className="inline-block md:hidden">
            <QuickAdd />
          </div>
        </div>
        <div
          className="logoWrapper hidden shrink-0 lg:inline-flex md:mt-[15px]"
          onClick={() => redirectToDashboard()}
        >
          <img
            className="w-[150px] sm:w-[124px] cursor-pointer"
            src="https://www.651carpets.com/wp-content/uploads/2024/03/651carpet_logo.png"
            alt=""
          />
        </div>
        <div className="topHeaderLeft inline-flex items-center lg:hidden">
          <Icon
            onClick={() => setSidebarCollapse(!isSidebarCollapse)}
            className={`cursor-pointer w-[30px] h-[30px] shrink-0 rounded-full bg-primaryColor p-[5px] pr-[6px] relative top-[-2px] mr-[10px] duration-300 hover:bg-primaryColorDark ${
              isSidebarCollapse ? "rotate-[180deg]" : ""
            }`}
            name="caretLeft"
            fill="var(--textWhiteCarpet)"
          />
          <h1 className="title text-textDark font-AcuminPro__Bold text-[20px] shrink-0">
            {headerTitle}
          </h1>
        </div>
        <div className="topHeaderRight w-full flex items-center justify-end pl-[20px] md:w-[calc(100%_-_150px)] md:mt-[15px]">
          <div className="inline-block lg:hidden">
            <QuickAdd />
          </div>
          <div className="inline-block relative topHeader__searchWrapper ml-[15px] lg:hidden">
            <InputField
              fieldWrapperClassName="formElementsBG__field mb-0 w-[430px] 3xl:w-[250px] md:hidden"
              placeholder="Search"
              icon="search"
              onChange={(e) => {
                setSearchData([]);
                getSearchData(e.target.value);
              }}
            />
            {searchData?.length ? (
            <div className="searchDropdown py-[10px] absolute top-[calc(100%_+_5px)] left-0 w-full bg-bgWhiteCarpet rounded-[8px] shadow-raiseShadow z-[5]">
              <div className="searchInner__wrapper max-h-[300px] overflow-y-auto fancyScroll">
                {searchData?.map((e) => (
                  <div className="mb-[15px] last:mb-0" key={e.model_name}>
                    <p className="text-[17px] font-AcuminPro__Semibold text-textDark px-[14px] mb-[10px]">{convertTitleCase(e.model_name)}</p>
                    {e.group.map((val) => (
                      <div className="searchItem cursor-pointer py-[6px] px-[14px] group relative before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-primaryColor before:opacity-0 before:duration-300 hover:before:opacity-10" key={val.id}>
                        <span
                          className="searchValue text-[14px] font-AcuminPro__Regular text-textSecondary relative z-[2] group-hover:text-primaryColor"
                          onClick={() => {
                            const model_name = e.model_name.toUpperCase();
                            const path = PRIVATE_NAVIGATION?.[model_name]?.EDIT;
                            navigate(setUrlParams(path, val.id));
                            setSearchData([]);
                          }}
                        >
                          {val.name}
                        </span>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          </div>
          {/* <Notification /> */}
          <div className="inline-block lg:hidden">
            <ProfileToggle />
          </div>
          <button
            onClick={() => sideBarToggle()}
            className="sidebarToggleBtn hidden ml-[8px] lg:inline-block sm:ml-[8px]"
          >
            <Icon
              className="darkHoverPrimary w-[38px] h-[38px] p-[5px] sm:w-[34px] sm:h-[34px]"
              name="dotsNineFilled"
            />
          </button>
        </div>
      </div>
      <div className="lgAddNew fixed top-[20px] right-[370px] z-[13] hidden lg:inline-block md:top-[11px] md:right-[12px]">
        <QuickAdd />
      </div>
      <div className="hidden relative topHeader__searchWrapper w-full lg:inline-block lg:w-[250px] lg:fixed lg:top-[20px] lg:right-[110px] lg:z-[12] md:right-unset md:left-0 md:top-[12px] md:w-[calc(100%_-_60px)] md:pl-[12px]">
        <InputField
          fieldWrapperClassName="formElementsBG__field mb-0"
          placeholder="Search"
          icon="search"
          onChange={(e) => {
            setSearchData([]);
            getSearchData(e.target.value);
          }}
        />
        {searchData?.length ? (
        <div className="searchDropdown py-[10px] absolute top-[calc(100%_+_5px)] left-0 w-full bg-bgWhiteCarpet rounded-[8px] shadow-raiseShadow z-[5] md:left-[12px] md:w-[calc(100%_+_35px)]">
          <div className="searchInner__wrapper max-h-[300px] overflow-y-auto fancyScroll">
            {searchData?.map((e) => (
              <div className="mb-[15px] last:mb-0" key={e.model_name}>
                <p className="text-[17px] font-AcuminPro__Semibold text-textDark px-[14px] mb-[10px]">{convertTitleCase(e.model_name)}</p>
                {e.group.map((val) => (
                  <div className="searchItem cursor-pointer py-[6px] px-[14px] group relative before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-primaryColor before:opacity-0 before:duration-300 hover:before:opacity-10" key={val.id}>
                    <span
                      className="searchValue text-[14px] font-AcuminPro__Regular text-textSecondary relative z-[2] group-hover:text-primaryColor"
                      onClick={() => {
                        const model_name = e.model_name.toUpperCase();
                        const path = PRIVATE_NAVIGATION?.[model_name]?.EDIT;
                        navigate(setUrlParams(path, val.id));
                        setSearchData([]);
                      }}
                    >
                      {val.name}
                    </span>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      ) : null}
      </div>
      <div className="lgProfileToggle hidden lg:inline-block lg:fixed lg:top-[20px] lg:right-[58px] lg:z-[11] md:top-[67px] md:right-[55px] sm:right-[51px]">
        <ProfileToggle />
      </div>
    </>
  );
};

export default Header;
