type Props = {
  titleClassName?: string;
  imageClassName?: string;
  description?: string;
  title?: string;
  descriptionClassName?: string;
  className?: string;
  hideImage?: boolean;
};

const NoDataFound = (props: Props) => {
  const {
    className = "",
    description = "",
    titleClassName = "",
    imageClassName = "",
    descriptionClassName = "",
    title = "No Result Found",
    hideImage = false,
  } = props;
  return (
    <div className={`noData__wrapper py-[35px] ${className}`}>
      {!hideImage ? (
        <img
          className={`${imageClassName}`}
          src="/images/noDataImg.png"
          alt=""
        />
      ) : (
        <></>
      )}
      <h2
        className={`title text-[24px] text-center font-AcuminPro__Bold text-textDark mt-[15px] mb-[6px] sm:text-[20px] ${titleClassName}`}
      >
        {title}
      </h2>
      <p
        className={`descriptionText text-[16px] text-textSecondary font-AcuminPro__Regular text-center w-[400px] max-w-full ${descriptionClassName}`}
      >
        {description}
      </p>
    </div>
  );
};

export default NoDataFound;
