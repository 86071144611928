// ** Packages **
import { startOfDay } from "date-fns";
import { forwardRef, useEffect, useRef } from "react";
import ReactDatePicker from "react-datepicker";
import { Controller } from "react-hook-form";
import MaskedInput from "react-text-mask";

// ** CSS **
import "react-datepicker/dist/react-datepicker.css";
// import "./style/reactDatePicker.css";

// ** Redux **

// ** Components **
import HelperText from "components/FormField/components/common/HelperText";
import Label from "components/FormField/components/common/Label";
import Icon from "components/Icon";

// ** Types **
import { ReactDatePickerPropsTypes } from "components/FormField/types/formField.types";

// ** Helper **
import { reactDatePickerSelectedDate } from "components/FormField/helper";

const DateField = <TFormValues extends Record<string, unknown>>(
  props: ReactDatePickerPropsTypes<TFormValues>,
  ref: React.Ref<ReactDatePicker<never, undefined>>
) => {
  const {
    icon,
    name,
    label,
    value,
    errors,
    control,
    maxDate,
    minDate,
    register,
    selected,
    iconClass = "",
    labelClass = "",
    errorClass = "",
    dateFormat = "",
    placeholder = "",
    required = false,
    disabled = false,
    wrapperClass = "",
    isIconRight = false,
    fieldWrapperClassName = "",
    fieldBGClassName = "",
    popperPosition = "fixed",
    popperPlacement = "bottom-start",
    showYearDropdown = true,
    showMonthDropdown = true,
    inline = false,
    showTimeSelect = false,
    showTimeSelectOnly = false,
    timeCaption = "",
    onChange,
    getOnChangeDateInput,
    open = false,
    onClickOutside,
    excludeTimes,
    placeholderText,
    dropdownMode,
  } = props;

  const isFormField = control && name;

  const formGroupClassName = `${errors?.message ? "field__has__error" : ""} ${
    disabled ? "disable" : ""
  } ${fieldWrapperClassName}`;

  const wrapperClassNames = `${icon ? "field__has__icon" : ""} ${
    isIconRight ? "icon__right" : ""
  } ${wrapperClass}`;

  const dateRef = useRef<HTMLDivElement>(null);
  const defaultDateFormat = dateFormat || "MM-dd-yyyy";
  const setMaskInput = () => {
    if (defaultDateFormat === "yyyy/MM/dd") {
      return [/\d/, /\d/, /\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/];
    }
    return [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
  };

  useEffect(() => {
    let elm: Element | null;
    if (showTimeSelect && dateRef.current) {
      elm = dateRef.current.querySelector(
        ".react-datepicker__navigation--previous"
      );
      if (
        !elm?.classList.contains(
          "react-datepicker__navigation--prev--with-time"
        )
      ) {
        elm?.classList.add("react-datepicker__navigation--prev--with-time");
      }
    }
    return () => {
      elm?.classList.remove("react-datepicker__navigation--prev--with-time");
    };
  });

  return (
    <div className={`field__wrapper ${formGroupClassName}`}>
      {label && (
        <Label label={label} required={required} labelClass={labelClass} />
      )}
      <div
        ref={dateRef}
        className={`field__inner__wrapper ${wrapperClassNames}`}
      >
        {isFormField ? (
          <Controller
            name={name}
            control={control}
            render={({
              field: { onChange: innerOnChange, value: innerValue },
            }) => (
              <ReactDatePicker
                isClearable
                autoComplete="off"
                scrollableYearDropdown
                scrollableMonthYearDropdown
                className="input__carpet"
                {...(dropdownMode && { dropdownMode: "select" })}
                {...(inline && { inline })}
                {...(showTimeSelect && { showTimeSelect })}
                {...(showTimeSelectOnly && { showTimeSelectOnly })}
                {...(minDate && { minDate })}
                {...(maxDate && { maxDate })}
                {...(disabled && { disabled })}
                {...(register && { register })}
                {...(placeholder && { placeholder })}
                {...(popperPlacement && { popperPlacement })}
                {...(showYearDropdown && { showYearDropdown })}
                {...(showMonthDropdown && { showMonthDropdown })}
                {...(showTimeSelect && { showTimeSelect })}
                {...(open && { open })}
                {...(!showTimeSelect && {
                  customInput: (
                    <MaskedInput type="text" mask={() => setMaskInput()} />
                  ),
                })}
                excludeTimes={excludeTimes}
                {...(timeCaption && { timeCaption })}
                dateFormat={defaultDateFormat}
                onChange={(date, event) => {
                  innerOnChange(
                    event && date ? startOfDay(date).toISOString() : date
                  );
                  if (
                    (showTimeSelect && !event) ||
                    (!showTimeSelect && event)
                  ) {
                    onChange?.(new Date(`${date}`));
                    getOnChangeDateInput?.(
                      dateRef.current?.querySelector("input")
                    );
                  }
                }}
                onClickOutside={onClickOutside}
                selected={reactDatePickerSelectedDate(
                  innerValue || selected || undefined
                )}
                placeholderText={
                  placeholder ||
                  placeholderText ||
                  defaultDateFormat.toUpperCase()
                }
                popperProps={{
                  ...(popperPosition && { strategy: popperPosition }),
                }}
                onCalendarOpen={() => {
                  if (showTimeSelect && dateRef.current) {
                    const elm = dateRef.current.querySelector(
                      ".react-datepicker__navigation--previous"
                    );

                    if (
                      !elm?.classList.contains(
                        "react-datepicker__navigation--prev--with-time"
                      )
                    ) {
                      elm?.classList.add(
                        "react-datepicker__navigation--prev--with-time"
                      );
                    }
                  }
                }}
              />
            )}
          />
        ) : (
          <ReactDatePicker
            ref={ref}
            isClearable
            autoComplete="off"
            dropdownMode="select"
            scrollableYearDropdown
            {...(value && { value })}
            className="input__carpet"
            scrollableMonthYearDropdown
            {...(inline && { inline })}
            {...(showTimeSelect && { showTimeSelect })}
            {...(showTimeSelectOnly && { showTimeSelectOnly })}
            {...(minDate && { minDate })}
            {...(maxDate && { maxDate })}
            {...(control && { control })}
            {...(disabled && { disabled })}
            {...(register && { register })}
            {...(placeholder && { placeholder })}
            {...(popperPlacement && { popperPlacement })}
            {...(showYearDropdown && { showYearDropdown })}
            {...(showMonthDropdown && { showMonthDropdown })}
            {...(timeCaption && { timeCaption })}
            {...(open && { open })}
            {...(showTimeSelect && { showTimeSelect })}
            {...(!showTimeSelect && {
              customInput: (
                <MaskedInput type="text" mask={() => setMaskInput()} />
              ),
            })}
            dateFormat={defaultDateFormat}
            placeholderText={
              placeholder || placeholderText || defaultDateFormat.toUpperCase()
            }
            onChange={(e) => {
              onChange?.(e);
              getOnChangeDateInput?.(dateRef.current?.querySelector("input"));
            }}
            onClickOutside={onClickOutside}
            selected={reactDatePickerSelectedDate(
              value || selected || undefined
            )}
            popperProps={{
              ...(popperPosition && { strategy: popperPosition }),
            }}
            onCalendarOpen={() => {
              if (showTimeSelect && dateRef.current) {
                const elm = dateRef.current.querySelector(
                  ".react-datepicker__navigation--previous"
                );

                if (
                  !elm?.classList.contains(
                    "react-datepicker__navigation--prev--with-time"
                  )
                ) {
                  elm?.classList.add(
                    "react-datepicker__navigation--prev--with-time"
                  );
                }
              }
            }}
          />
        )}
        {label && (
          <Label label={label} required={required} labelClass={labelClass} />
        )}
        <span className={`bgWrapper ${fieldBGClassName}`} />
        {icon && <Icon name={icon} className={iconClass} />}
      </div>
      {errors?.message && (
        <HelperText
          helperText={errors?.message || ""}
          helperTextClass={`error__text ${errorClass}`}
        />
      )}
    </div>
  );
};

export default forwardRef(DateField) as <
  TFormValues extends Record<string, unknown>
>(
  props: ReactDatePickerPropsTypes<TFormValues> & {
    ref?: React.Ref<ReactDatePicker<never, undefined>>;
  }
) => JSX.Element;
