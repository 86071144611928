// ** Packages **
import axios, { AxiosRequestConfig } from "axios";
import { Store } from "@reduxjs/toolkit";

// ** Redux **
import { removeToast, setToast } from "redux/slices/toastSlice";
import { setLogoutData } from "redux/slices/authSlice";

// ** Types**
import { ApiResponseType } from "base-axios/types";

// ** Others **
import { REACT_APP_API_URL } from "config";

export const Axios = axios.create({ baseURL: `${REACT_APP_API_URL}` });

export const setupAxios = (store: Store) => {
  // logic of set token in header
  Axios.interceptors.request.use((request) => {
    const clientIp = localStorage.getItem("clientIp");

    if (request.headers && clientIp) {
      request.headers["x-client-ip"] = `${clientIp}`;
    }

    if (request.headers) {
      request.headers["x-client-tz"] =
        Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    request.withCredentials = true;
    return request;
  });
  // for toast message setup
  Axios.interceptors.response.use(
    (res) => {
      const { toast } = res.data;
      if (toast) {
        // ----------- set api response toast -----------
        const toastId = new Date().getTime();
        store.dispatch(
          setToast({
            message: res.data.message,
            type: res.data.responseType,
            id: toastId,
          })
        );
        setTimeout(() => {
          store.dispatch(removeToast({ id: toastId }));
        }, 2000);
      }
      return res.data;
    },
    (e) => {
      if (e.response.status === 401) {
        const storeData = store.getState();
        if (storeData.token !== null) {
          store.dispatch(setLogoutData());
        }
      }
      if (
        e.response.status === 400 ||
        e.response.status === 500 ||
        e.response.status === 401 ||
        e.response.status === 422
      ) {
        const { toast, data } = e.response.data;
        if (e.response.data === "showCustomError") {
          return;
        }
        if (toast) {
          // ----------- set api response toast -----------
          const toastId = new Date().getTime();
          store.dispatch(
            setToast({
              message: e.response.data.message,
              type: e.response.data.responseType,
              id: toastId,
            })
          );
          setTimeout(() => {
            store.dispatch(removeToast({ id: toastId }));
          }, data?.toastDuration || 2000);
        }
      }

      throw e.response.data;
    }
  );
};

// ******************
type AxiosArgsType = Readonly<{
  url: string;
  method?: AxiosRequestConfig["method"];
  data?: AxiosRequestConfig["data"];
  params?: AxiosRequestConfig["params"];
  extraOptions?: AxiosRequestConfig;
}>;

export const axiosBaseQuery = async (args: AxiosArgsType) => {
  try {
    const result = await Axios({
      url: args.url,
      method: args.method,
      data: args.data,
      params: args.params,
      ...args.extraOptions,
    });

    return { data: result.data };
  } catch (axiosError) {
    const err = axiosError as ApiResponseType;
    return {
      error: err,
    };
  }
};

export default axios;
