// ** Redux **
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootStateType } from "redux/store";

// ** Util **
import { Option } from "components/FormField/types/formField.types";
import { DynamicObjectType } from "types";

type LeadRecordType = {
  id: number;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  address: string;
  city: string;
  state: Option;
  zip: string;
  job_status: Option;
  customer_no: string;
  product_type: Option;
  mat_type: Option;
  room: Option;
  budget: string;
  appointment_date: Date | string;
  appointment_time: Date | string;
  sales_id: Option;
  referral_source: Option;
};

export type LeadSliceType = {
  list?: DynamicObjectType[];
  record?: LeadRecordType | null;
};

const initialState: LeadSliceType = {
  list: [],
  record: null,
};

const slice = createSlice({
  name: "lead",
  initialState,
  reducers: {
    setLeads(state: LeadSliceType, action: PayloadAction<LeadSliceType>) {
      state.list = action.payload.list;
    },
    setLeadRecord(state: LeadSliceType, action: PayloadAction<LeadSliceType>) {
      state.record = action.payload.record;
    },
  },
});

export const { reducer } = slice;

export const { setLeads, setLeadRecord } = slice.actions;

export const getLeadList = (state: RootStateType) => state.lead.list;

export const getLeadRecord = (state: RootStateType) => state.lead.record;

export default slice;
