// ** Packages **
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// ** Redux **
import { RootStateType } from "redux/store";

// ** Types **
import { DynamicObjectType } from "types";

export type TeamSliceType = {
  list?: DynamicObjectType[];
  record?: DynamicObjectType | null;
};

const initialState: TeamSliceType = {
  list: [],
  record: null,
};

const slice = createSlice({
  name: "team",
  initialState,
  reducers: {
    setTeamList(state: TeamSliceType, action: PayloadAction<TeamSliceType>) {
      state.list = action.payload.list;
    },
    setTeamMemberRecord(
      state: TeamSliceType,
      action: PayloadAction<TeamSliceType>
    ) {
      state.record = action.payload.record;
    },
  },
});

export const { reducer } = slice;

export const { setTeamList, setTeamMemberRecord } = slice.actions;

export const getTeamList = (state: RootStateType) => state.team.list;

export const getTeamMemberRecord = (state: RootStateType) => state.team.record;

export default slice;
