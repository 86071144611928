// ** Packages **
import axios from "axios";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { MutableRefObject } from "react";
import { FieldErrors } from "react-hook-form";
import slugify from "slugify";

export const stringToBoolean = (value: any) => {
  if (value === "true" || value === true || value === "1") {
    return true;
  }
  if (value === "false" || value === false || value === "0") {
    return false;
  }
  return false;
};

export const convertIntoInt = (value: string | number | null | undefined) => {
  return value && !Number.isNaN(Number(value))
    ? Math.round(Number(value))
    : null;
};

export const convertIntoNumber = (
  value: string | number | null | undefined
) => {
  if (typeof value === "string") {
    value = value.replaceAll(",", "");
  }
  return value && !Number.isNaN(Number(value)) ? Number(value) : null;
};

export const setDateType = (value: string | Date | null | undefined) => {
  if (value) {
    if (!Number.isNaN(new Date(value).getTime())) {
      return new Date(value);
    }
    return null;
  }
  return null;
};

export const downloadAttachmentFile = async ({
  url,
  fileName,
}: {
  url: string;
  fileName: string;
}) => {
  const originalImage = `${url}`;
  const linkTag = document.createElement("a");
  linkTag.download = fileName;

  try {
    const response = await axios.get(originalImage, {
      responseType: "arraybuffer",
    });

    linkTag.href = URL.createObjectURL(new Blob([response.data]));
    linkTag.click();
    // Rest of the code...
  } catch (error) {
    console.error("Axios Error:", error);
  }
};

export const stringToSlugify = (string: string) => {
  return slugify(string, {
    trim: true,
    lower: true,
    replacement: "-",
  });
};

export const copyToClipboard = (value: string) => {
  navigator.clipboard.writeText(value);
};

export const focusOnError = (
  ref: MutableRefObject<Record<string, HTMLDivElement | null>>,
  errors: FieldErrors<any> | undefined = {}
) => {
  const firstErrorKey = Object.keys(errors)?.[0];

  if (firstErrorKey && Object.hasOwn(ref?.current, firstErrorKey)) {
    ref?.current?.[firstErrorKey]?.scrollIntoView();
  }
};

export const getTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getTimeString = (date?: Date, timeFormat?: string) => {
  return format(
    date
      ? utcToZonedTime(new Date(date), getTimeZone())
      : utcToZonedTime(new Date(), getTimeZone()),
    timeFormat ?? "HH:mm:ss"
  );
};

export const getFormattedDateOnly = (date?: Date | string) => {
  return date
    ? format(typeof date === "string" ? new Date(date) : date, "MM/dd/yyyy")
    : "-";
};

export const formatDescription = (value?: string) => {
  if (value?.length) {
    return value
      ?.replace(/\\n/g, "\n")
      ?.replace(/\\r/g, "\r")
      ?.replace(/\\"/g, '"')
      ?.replace(/\\'/g, "'")
      ?.slice(1, -1);
  }
  return "";
};

export const formatStringName = (matType?: string) => {
  if (!matType) {
    return "";
  }
  // Split the string into an array of words
  const words = matType.split("_");
  // Capitalize the first letter of each word
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  // Join the capitalized words back together
  return capitalizedWords.join(" ");
};

export const getFullName = (firstName?: string, lastName?: string) => {
  if (Boolean(firstName) && Boolean(lastName)) {
    return `${firstName} ${lastName}`;
  }
  return firstName || "";
};

export const formatToTwoDecimal = (props: { value: any; digit?: number }) => {
  const { value, digit = 2 } = props;
  return parseFloat(value?.toString()).toFixed(digit) as unknown as number;
};

export const withDollarSign = (props: { value: any }) => {
  const { value } = props;
  return `$${value}`;
};

export const toCapitalizeName = (input: string) => {
  return input
    ?.split("_")
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    ?.join(" ");
};
