// ** Packages **
import _, { debounce } from "lodash";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { MultiValue, SingleValue, StylesConfig } from "react-select";
import CreatableSelect from "react-select/creatable";

// ** CSS **
// import "../style/select.css";

// ** Components **
import HelperText from "components/FormField/components/common/HelperText";
import Label from "components/FormField/components/common/Label";
import Icon from "components/Icon";

// ** Types **
import {
  Option,
  ReactSelectPropsTypes,
} from "components/FormField/types/formField.types";

// ** Util **
import { fontColorBasedOnBgColor } from "utils";

// ** Helper **
import { isMultiValue } from "components/FormField/helper";

// ** Constant **
import { TAGS_COLOR } from "../../../../../constants";

// for based on label color style
const colorStyles: StylesConfig<Option, true> = {
  multiValue: (styles, { data }) => {
    const fontColor = fontColorBasedOnBgColor(data.color, "white", "black");
    return {
      ...styles,
      backgroundColor: `${data.color} !important`,
      color: `${fontColor} !important`,
    };
  },
  multiValueLabel: (styles, { data }) => {
    const fontColor = fontColorBasedOnBgColor(data.color, "white", "black");
    return {
      ...styles,
      color: `${fontColor} !important`,
    };
  },
  multiValueRemove: (styles, { data }) => {
    const fontColor = fontColorBasedOnBgColor(data.color, "white", "black");
    return {
      ...styles,
      color: `${fontColor} !important`,
      ":before": {
        backgroundColor: `${fontColor} !important`,
        color: `${fontColor} !important`,
        height: "1px !important",
      },
      ":after": {
        backgroundColor: `${fontColor} !important`,
        color: `${fontColor} !important`,
        height: "1px !important",
      },
    };
  },
};

const CreatableAsyncSelectField = <TFormValues extends Record<string, unknown>>(
  props: ReactSelectPropsTypes<TFormValues>
) => {
  const {
    name,
    label,
    value,
    errors,
    control,
    icon,
    onChange,
    getOptions,
    getOnChange,
    iconClass = "",
    errorClass = "",
    defaultOptions,
    labelClass = "",
    isMulti = false,
    placeholder = "",
    OptionComponent,
    required = false,
    noOptionsMessage,
    isValidNewOption,
    disabled = false,
    fieldBGClassName = "",
    wrapperClass = "",
    isLoading = false,
    isIconRight = false,
    fieldWrapperClassName = "",
    MultiValueComponent,
    singleValueComponent,
    inputMaxLength = 100,
    menuPosition = "fixed",
    menuPlacement = "auto",
    serveSideSearch = false,
    isMultiColor = false,
  } = props;

  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [options, setOptions] = useState<Option[]>(defaultOptions || []);
  const [loading, setLoading] = useState(isLoading);

  const fetchOption = async ({
    pageNo = page,
    optionValue = search,
    first = false,
    pagination = false,
  } = {}) => {
    if (
      getOptions &&
      ((page !== 1 && optionValue !== search) || first || pagination)
    ) {
      setLoading(true);
      const data = await getOptions({
        search: optionValue,
        page: pageNo,
        name,
      });
      if (data) {
        setTotal(data.count || 0);
        if (pageNo !== 1) {
          const tempOptions = [...options];
          data.option.forEach((op) => {
            if (!tempOptions.find((el) => el.value === op.value)) {
              tempOptions.push(op);
            }
          });
          setOptions(tempOptions);
        } else {
          setOptions(data.option);
        }
        setPage(pageNo + 1);
      } else {
        setPage(1);
        setSearch("");
        setTotal(0);
      }
      setLoading(false);
      return data;
    }
    return undefined;
  };

  const onMenuScrollToBottom = (e: any) => {
    if (
      e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight &&
      total > options.length
    ) {
      fetchOption({ pagination: true });
    }
  };

  const onInputChange = debounce((e: string) => {
    setSearch(e);

    fetchOption({ optionValue: e, pageNo: 1 });
  }, 200);

  return (
    <div
      className={`field__wrapper ${
        errors?.message ? "field__has__error" : ""
      } ${disabled ? "disable" : ""} ${fieldWrapperClassName}`}
    >
      <div className={`selectCarpet ${wrapperClass}`}>
        {label && (
          <Label label={label} required={required} labelClass={labelClass} />
        )}
        <div
          className={`field__inner__wrapper ${icon ? "field__has__icon" : ""} ${
            isIconRight ? "icon__right" : ""
          }`}
        >
          {control && name ? (
            <Controller
              name={name}
              control={control}
              render={({
                field: { onChange: innerOnChange, value: innerValue, ref },
              }) => {
                return (
                  <CreatableSelect
                    ref={ref}
                    openMenuOnClick
                    options={options}
                    isDisabled={disabled}
                    isLoading={isLoading || loading}
                    className="basic-single"
                    isClearable
                    placeholder={placeholder}
                    menuPosition={menuPosition}
                    classNamePrefix="selectCarpet"
                    menuPlacement={menuPlacement}
                    {...(isMulti && { isMulti })}
                    {...(isMultiColor && { styles: colorStyles })}
                    onMenuScrollToBottom={onMenuScrollToBottom}
                    {...(noOptionsMessage && { noOptionsMessage })}
                    {...(isMulti && { defaultValue: defaultOptions })}
                    value={
                      innerValue as MultiValue<Option> | SingleValue<Option>
                    }
                    onMenuOpen={() => fetchOption({ first: true, pageNo: 1 })}
                    onInputChange={serveSideSearch ? onInputChange : undefined}
                    isValidNewOption={(InputValue: string) => {
                      if (isValidNewOption) {
                        return isValidNewOption(InputValue);
                      }
                      if (!InputValue.trim().length) {
                        return false;
                      }
                      if (inputMaxLength) {
                        return InputValue?.length > inputMaxLength
                          ? false
                          : !!InputValue;
                      }
                      return !!InputValue;
                    }}
                    openMenuOnFocus
                    onChange={(selectedOption: any) => {
                      const isOptionArray = _.isArray(selectedOption);
                      let trimmedSelectedOption = selectedOption;
                      if (isOptionArray && trimmedSelectedOption?.length) {
                        const clonedTrimmedSelectedOption = _.cloneDeep(
                          trimmedSelectedOption
                        );
                        clonedTrimmedSelectedOption[0].label =
                          clonedTrimmedSelectedOption[0].label.trim();
                        clonedTrimmedSelectedOption[0].value =
                          typeof clonedTrimmedSelectedOption[0]?.value ===
                          "string"
                            ? clonedTrimmedSelectedOption[0]?.value?.trim()
                            : clonedTrimmedSelectedOption[0]?.value;
                        trimmedSelectedOption = clonedTrimmedSelectedOption;
                      } else if (
                        typeof selectedOption === "object" &&
                        selectedOption?.value
                      ) {
                        trimmedSelectedOption = {
                          ...selectedOption,
                          label: selectedOption?.label?.trim(),
                          value:
                            typeof selectedOption?.value === "string"
                              ? selectedOption?.value?.trim()
                              : selectedOption?.value,
                        };
                      }
                      if (isMultiColor) {
                        if (isMultiValue(trimmedSelectedOption)) {
                          const prevTagColor =
                            trimmedSelectedOption[
                              trimmedSelectedOption.length - 2
                            ]?.color;
                          trimmedSelectedOption.forEach((obj: Option) => {
                            if (!obj.color) {
                              obj.color = _.sample(
                                TAGS_COLOR.filter(
                                  (color: string) => color !== prevTagColor
                                )
                              );
                            }
                          });
                          onChange?.(trimmedSelectedOption);
                        }
                      }
                      onChange?.(trimmedSelectedOption);
                      innerOnChange(trimmedSelectedOption);
                      getOnChange?.(trimmedSelectedOption);
                    }}
                    components={{
                      ...(OptionComponent && { Option: OptionComponent }),
                      ...(singleValueComponent && {
                        SingleValue: singleValueComponent,
                      }),
                      ...(MultiValueComponent && {
                        MultiValueLabel: MultiValueComponent,
                      }),
                    }}
                  />
                );
              }}
            />
          ) : (
            <CreatableSelect
              value={value}
              openMenuOnClick
              options={options}
              isDisabled={disabled}
              isLoading={isLoading || loading}
              className="basic-single"
              placeholder={placeholder}
              isClearable
              menuPosition={menuPosition}
              classNamePrefix="selectCarpet"
              {...(isMulti && { isMulti })}
              menuPlacement={menuPlacement}
              onMenuScrollToBottom={onMenuScrollToBottom}
              {...(noOptionsMessage && { noOptionsMessage })}
              {...(isMulti && { defaultValue: defaultOptions })}
              onMenuOpen={() => fetchOption({ first: true, pageNo: 1 })}
              onInputChange={serveSideSearch ? onInputChange : undefined}
              isValidNewOption={(InputValue: string) => {
                if (isValidNewOption) {
                  return isValidNewOption(InputValue);
                }
                if (!InputValue.trim().length) {
                  return false;
                }
                if (inputMaxLength) {
                  return InputValue?.length > inputMaxLength
                    ? false
                    : !!InputValue;
                }
                return !!InputValue;
              }}
              onChange={(selectedOption: any) => {
                const isOptionArray = _.isArray(selectedOption);
                let trimmedSelectedOption = selectedOption;
                if (isOptionArray) {
                  const clonedTrimmedSelectedOption: any = _.cloneDeep(
                    trimmedSelectedOption
                  );
                  clonedTrimmedSelectedOption[0].label =
                    clonedTrimmedSelectedOption[0].label.trim();
                  clonedTrimmedSelectedOption[0].value =
                    typeof clonedTrimmedSelectedOption[0]?.value === "string"
                      ? clonedTrimmedSelectedOption[0]?.value?.trim()
                      : clonedTrimmedSelectedOption[0]?.value;
                  trimmedSelectedOption = clonedTrimmedSelectedOption;
                } else {
                  trimmedSelectedOption = {
                    ...selectedOption,
                    label: selectedOption?.label?.trim(),
                    value:
                      typeof selectedOption?.value === "string"
                        ? selectedOption?.value?.trim()
                        : selectedOption?.value,
                  };
                }
                onChange?.(trimmedSelectedOption);
                getOnChange?.(trimmedSelectedOption);
              }}
              components={{
                ...(OptionComponent && { Option: OptionComponent }),
                ...(singleValueComponent && {
                  SingleValue: singleValueComponent,
                }),
                ...(MultiValueComponent && {
                  MultiValueLabel: MultiValueComponent,
                }),
              }}
            />
          )}
          {label && (
            <Label label={label} required={required} labelClass={labelClass} />
          )}
          <span className={`bgWrapper ${fieldBGClassName}`} />
          {icon && <Icon className={iconClass} name={icon} />}
        </div>
      </div>
      {errors?.message && (
        <HelperText
          helperText={errors?.message || ""}
          helperTextClass={`error__text ${errorClass}`}
        />
      )}
    </div>
  );
};

export default CreatableAsyncSelectField;
