// ** Packages **
import ReactDatePicker from "react-datepicker";
import { Controller } from "react-hook-form";

// ** CSS **
// import "./style/reactDatePicker.css";

// ** Components **
import HelperText from "components/FormField/components/common/HelperText";
import Label from "components/FormField/components/common/Label";
import Icon from "components/Icon";

// ** Types **
import { ReactDatePickerPropsTypes } from "components/FormField/types/formField.types";

// ** Helper **
import { reactDatePickerSelectedDate } from "components/FormField/helper";

export const TimeField = <TFormValues extends Record<string, unknown>>(
  props: ReactDatePickerPropsTypes<TFormValues>
) => {
  const {
    name,
    label = "",
    errors,
    value,
    control,
    icon,
    maxDate,
    minDate,
    onChange,
    register,
    selected,
    iconClass = "",
    labelClass = "",
    errorClass = "",
    disabled = false,
    required = false,
    wrapperClass = "",
    isIconRight = false,
    popperPosition = "fixed",
    fieldWrapperClassName = "",
    fieldBGClassName = "",
    dateFormat = "HH:mm",
    popperPlacement = "bottom-start",
  } = props;

  return (
    <div
      className={`field__wrapper ${
        errors?.message ? "field__has__error" : ""
      } ${disabled ? "disable" : ""} ${fieldWrapperClassName}`}
    >
      <Label label={label} required={required} labelClass={labelClass} />
      <div
        className={`field__inner__wrapper ${icon ? "field__has__icon" : ""} ${
          isIconRight ? "icon__right" : ""
        } ${wrapperClass}`}
      >
        {control && name ? (
          <Controller
            name={name}
            control={control}
            render={({
              field: { onChange: innerOnChange, value: innerValue },
            }) => (
              <>
                <ReactDatePicker
                  isClearable
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  disabled={disabled}
                  timeFormat="hh:mm aa"
                  dateFormat={dateFormat}
                  className="input__carpet"
                  placeholderText={dateFormat}
                  {...(minDate && { minDate })}
                  {...(maxDate && { maxDate })}
                  {...(register && { register })}
                  {...(popperPlacement && { popperPlacement })}
                  onChange={(e) => innerOnChange(e ? e.toISOString() : e)}
                  selected={reactDatePickerSelectedDate(
                    innerValue ?? selected ?? undefined
                  )}
                  popperProps={{
                    ...(popperPosition && { strategy: popperPosition }),
                  }}
                />
              </>
            )}
          />
        ) : (
          <ReactDatePicker
            isClearable
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            disabled={disabled}
            timeFormat="hh:mm aa"
            dateFormat={dateFormat}
            className="input__carpet"
            placeholderText={dateFormat}
            {...(minDate && { minDate })}
            {...(maxDate && { maxDate })}
            {...(register && { register })}
            {...(popperPlacement && { popperPlacement })}
            onChange={(e) => {
              onChange?.(e);
            }}
            selected={reactDatePickerSelectedDate(
              value ?? selected ?? undefined
            )}
            popperProps={{
              ...(popperPosition && { strategy: popperPosition }),
            }}
          />
        )}
        <Label label={label} required={required} labelClass={labelClass} />
        <span className={`bgWrapper ${fieldBGClassName}`} />
        {icon && <Icon name={icon} className={iconClass} />}
      </div>
      {errors?.message && (
        <HelperText
          helperText={errors?.message || ""}
          helperTextClass={`error__text ${errorClass}`}
        />
      )}
    </div>
  );
};

export default TimeField;
