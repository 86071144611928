// ** Packages **
import { combineReducers } from "@reduxjs/toolkit";

// ** Redux Slices **
import { reducer as authReducer } from "./slices/authSlice";
import { reducer as customerReducer } from "./slices/customerSlice";
import { reducer as filterReducer } from "./slices/filterSlice";
import { reducer as generalSettingReducer } from "./slices/generalSettingSlice";
import { reducer as leadReducer } from "./slices/leadSlice";
import { reducer as productReducer } from "./slices/productSlice";
import { reducer as teamReducer } from "./slices/teamSlice";
import { reducer as toastReducer } from "./slices/toastSlice";
import { reducer as permissionReducer } from "./slices/permissionSlice";

const combineReducer = combineReducers({
  auth: authReducer,
  toast: toastReducer,
  team: teamReducer,
  filter: filterReducer,
  lead: leadReducer,
  customer: customerReducer,
  product: productReducer,
  generalSetting: generalSettingReducer,
  permission: permissionReducer,
});

const rootReducer = (state: any, action: any) => {
  // Clear all data in redux store to initial.
  if (action.type === "LOGOUT") state = undefined;
  return combineReducer(state, action);
};

export default rootReducer;
