// ** Packages **
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { RootStateType } from "redux/store";

// ** Redux **

type PermissionType = {
  permissions: string[];
};

const initialState: PermissionType = {
  permissions: [],
};

const slice = createSlice({
  name: "permission",
  initialState,
  reducers: {
    setCurrentUserPermissions(
      state: PermissionType,
      action: PayloadAction<string[]>
    ) {
      state.permissions = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const { reducer } = slice;

export const { setCurrentUserPermissions } = slice.actions;

export const getCurrentUserPermissions = (state: RootStateType) =>
  state.permission.permissions;

export default slice;
