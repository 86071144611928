import Button from "components/Button";
import Icon from "components/Icon";
import Image from "components/Image";
import Table from "components/Table";
import { DynamicObjectType } from "types";

const StaticTable = () => {
  const columns = [
    {
      name: "Name",
      selector: (row: DynamicObjectType) => row.name,
      sortable: true,
    },
    {
      name: "Address",
      selector: (row: DynamicObjectType) => row.address,
    },
    {
      name: "Created",
      selector: (row: DynamicObjectType) => row.date,
    },
    {
      name: "Created",
      selector: (row: DynamicObjectType) => row.aptDate,
    },
    {
      name: "Phone",
      selector: (row: DynamicObjectType) => row.phone,
    },
    {
      name: "Action",
      selector: (row: DynamicObjectType) => (
        <Button className="primaryBtn !py-[7px] h-[34px] before:rounded-[100px]">{row.buttonText}</Button>
      ),
    },
    {
      name: "SIs",
      selector: (row: DynamicObjectType) => (
        <>
        <Image avatarWrapperClassName="w-[36px] h-[36px] hidden" avatarInnerWrapperClassName="rounded-full" noImgIconWrapperClass="cursor-pointer" iconName={row.sis} />
        <div className="actionBtn__wrapper">
          <button className="actionBtn ml-[7px] first:ml-0">
            <Icon className="w-[32px] h-[32px] p-[7px] successHoverWhite rounded-full before:content-[''] before:rounded-full before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-successCarpet before:opacity-10 hover:before:opacity-100" name="viewPro" />
          </button>
          <button className="actionBtn ml-[7px] first:ml-0">
            <Icon className="w-[32px] h-[32px] p-[7px] primaryHoverWhite rounded-full before:content-[''] before:rounded-full before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-primaryColor before:opacity-10 hover:before:opacity-100" name="editPro" />
          </button>
          <button className="actionBtn ml-[7px] first:ml-0">
            <Icon className="w-[32px] h-[32px] p-[7px] dangerOnHoverWhite rounded-full before:content-[''] before:rounded-full before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-dangerCarpet before:opacity-10 hover:before:opacity-100" name="deletePro" />
          </button>
        </div>
        </>
      ),
    },
  ];

  const dataObj = Array(55)
    .fill(null)
    .map((_, index) => {
      const id = index + 1;
      return {
        id,
        name: `User ${id}`,
        aptDate: "08/24/23 - 11 AM",
        address: "2696 New Century Place Roseville MN 55113",
        date: "07/13/23",
        phone: "651-123-1234",
        buttonText: "estimate",
        sis: "plusCircle",
      };
    });

  return (
    <div className="mb-[30px]">
      <Table columns={columns} data={dataObj} />
    </div>
  );
};

export default StaticTable;
