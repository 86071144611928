import CommonReportCard from "./commonReportCard";
import RecentReportCard from "./recentReportCard";

const ReportStaticPage = () => {
    return (
        <>
            <div className="horizontalTab mb-[30px]">
                <div className="horizontalTab__inner">
                    <div className="horizontalTab__item active">
                        <span className="horizontalTab__link">
                            <span className="horizontalTab__name">Common Reports</span>
                        </span>
                    </div>
                    <div className="horizontalTab__item">
                        <span className="horizontalTab__link">
                            <span className="horizontalTab__name">Recent Reports</span>
                        </span>
                    </div>
                </div>
            </div>
            <div className="reportTab__cn">
                {/* Common Reports Start */}
                <div className="commonReports__tab">
                    <div className="commonReports__row mx-[-18px] flex flex-wrap">
                        <div className="commonReports__col w-1/5 px-[18px] mb-[35px]">
                            <CommonReportCard />
                        </div>
                        <div className="commonReports__col w-1/5 px-[18px] mb-[35px]">
                            <CommonReportCard />
                        </div>
                        <div className="commonReports__col w-1/5 px-[18px] mb-[35px]">
                            <CommonReportCard />
                        </div>
                        <div className="commonReports__col w-1/5 px-[18px] mb-[35px]">
                            <CommonReportCard />
                        </div>
                        <div className="commonReports__col w-1/5 px-[18px] mb-[35px]">
                            <CommonReportCard />
                        </div>
                        <div className="commonReports__col w-1/5 px-[18px] mb-[35px]">
                            <CommonReportCard />
                        </div>
                        <div className="commonReports__col w-1/5 px-[18px] mb-[35px]">
                            <CommonReportCard />
                        </div>
                        <div className="commonReports__col w-1/5 px-[18px] mb-[35px]">
                            <CommonReportCard />
                        </div>
                        <div className="commonReports__col w-1/5 px-[18px] mb-[35px]">
                            <CommonReportCard />
                        </div>
                        <div className="commonReports__col w-1/5 px-[18px] mb-[35px]">
                            <CommonReportCard />
                        </div>
                    </div>
                </div>
                {/* Common Reports end */}

                {/* Recent Reports Start */}
                <div className="recentReports__tab">
                    <div className="recentReports__row mx-[-18px] flex flex-wrap">
                        <div className="recentReports__col w-1/3 px-[18px] mb-[35px]">
                            <RecentReportCard />
                        </div>
                        <div className="recentReports__col w-1/3 px-[18px] mb-[35px]">
                            <RecentReportCard />
                        </div>
                        <div className="recentReports__col w-1/3 px-[18px] mb-[35px]">
                            <RecentReportCard />
                        </div>
                        <div className="recentReports__col w-1/3 px-[18px] mb-[35px]">
                            <RecentReportCard />
                        </div>
                        <div className="recentReports__col w-1/3 px-[18px] mb-[35px]">
                            <RecentReportCard />
                        </div>
                        <div className="recentReports__col w-1/3 px-[18px] mb-[35px]">
                            <RecentReportCard />
                        </div>
                    </div>
                </div>
                {/* Recent Reports End */}
            </div>
        </>
    );
}
export default ReportStaticPage;