// ** Packages **
import { format, parseISO } from "date-fns";
import { decode, encode, isValid } from "js-base64";
import _ from "lodash";
import tlds from "tlds";

// ** Type **
import { Option } from "components/FormField/types/formField.types";

export const logger = (value: any) => {
  if (process.env.NODE_ENV === "development") {
    console.log("error------", value?.message ? value?.message : value);
  }
};

export const setUrlParams = (url: string, Id: number | string) => {
  return url.replace(":id", Id?.toString());
};

export const encodeToBase64 = (data: string, urlSafe = false) => {
  return encode(data, urlSafe);
};

export const decodeFromBase64 = (data: string) => {
  return isValid(data) ? decode(data) : "";
};

export const isValidBase64 = (data: string) => {
  return isValid(data);
};

export const parseData = (data: any) => {
  try {
    return JSON.parse(data);
  } catch (e) {
    return null;
  }
};

export const changeMaskInputValueFunction = (
  value: string,
  fieldName: string,
  onChange: (...event: any[]) => void
) => {
  switch (fieldName) {
    case "mask_input_phone":
      return onChange(value.length ? value?.replace(/[^A-Z0-9]/gi, "") : value);
    case "mask_input_time":
    default:
      return onChange(value);
  }
};

export const formatMaskValue = (
  value: string | number,
  inputTypeName: string
) => {
  switch (inputTypeName) {
    case "mask_input_phone":
      if (value) {
        const cleaned = value?.toString()?.replace(/\D/g, "") || "";
        const match = cleaned?.match(
          /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
        );
        if (match) {
          const intlCode = match[1] ? "+1 " : "";
          return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
            ""
          );
        }
        return cleaned;
      }
      return null;
    case "mask_input_time":
    default:
      return value;
  }
};

export const checkInputIsNumber = (e: any) => {
  const k = e.which;
  if ((k < 48 || k > 57) && (k < 96 || k > 105) && k !== 8) {
    e.preventDefault();
    return false;
  }
};

export const isValidEmail = (v: string | null | undefined) => {
  const tld = (v || "").split(".").slice(-1)[0];

  const isValidTLDs = tlds.includes(tld);
  if (!isValidTLDs) {
    return false;
  }
  return true;
};

export const isValidDomain = (input: string | null | undefined) => {
  const tld = (input || "").split(".").slice(-1)[0];
  const isValidTLDs = tlds.includes(tld);
  if (
    input &&
    input.indexOf("@") === -1 &&
    input.indexOf(".") > 0 &&
    isValidTLDs
  ) {
    return true;
  }
  return false;
};

export const isValidDate = (date: string | Date) => {
  if (typeof date === "string") {
    return (
      Object.prototype.toString.call(new Date(date)).slice(8, -1) === "Date" &&
      new Date(date)?.toString() !== "Invalid Date"
    );
  }
  return date instanceof Date && new Date(date)?.toString() !== "Invalid Date";
};

export const getUrlHostName = (urlString: string) => {
  try {
    const url = new URL(urlString);
    if (url.protocol === "http:" || url.protocol === "https:") {
      return url.hostname;
    }
  } catch (error) {
    return "";
  }
};

export const isNumberInRange = (
  enteredNumber: string | number | undefined,
  number_min: string | number | undefined,
  number_max: string | number | undefined
) => {
  number_min = Number(number_min) || undefined;
  number_max = Number(number_max) || undefined;
  enteredNumber = Number(enteredNumber);
  const inRange =
    (number_min !== undefined ? enteredNumber >= number_min : true) &&
    (number_max !== undefined ? enteredNumber <= number_max : true);
  return inRange;
};

export const isNumberInteger = (
  enteredNumber: number,
  has_allow_decimal: boolean
) => {
  if (has_allow_decimal) {
    return true;
  }
  return _.isInteger(enteredNumber);
};

export const isDomainFromList = (domainList: Option[], emailDomain: string) => {
  const domainListOptions = (domainList || [])?.map((val: Option) => val.label);
  return domainListOptions.includes(emailDomain);
};

export const isPercentageValid = (
  enteredNumber: string,
  number_min: number,
  number_max: number,
  has_allow_multiple: boolean,
  multiple_of: number,
  has_allow_decimal: boolean
) => {
  const enteredNumberAsNumber = Number(enteredNumber);
  if (
    isNumberInteger(enteredNumberAsNumber, has_allow_decimal) &&
    isNumberInRange(enteredNumberAsNumber, number_min, number_max) &&
    ((has_allow_multiple &&
      (_.isNaN(multiple_of) || enteredNumberAsNumber % multiple_of === 0)) ||
      (has_allow_decimal && (enteredNumberAsNumber * 100) % 1 === 0) ||
      (!has_allow_decimal && !has_allow_multiple))
  ) {
    return true;
  }
  return false;
};

export const formatPhoneNumber = (value = "") => {
  if (typeof value === "string") {
    const cleaned = value?.replace(/\D/g, "");
    const match = cleaned?.match(
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
    );
    if (match) {
      const intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }
  return null;
};

export const searchItemFromArray = (data: any[], search: string) => {
  const searchData = data.filter((obj) => {
    return JSON.stringify(obj?.template_name || "")
      .toLocaleLowerCase()
      .includes(search.trim().toString());
  });
  if (_.isArray(searchData)) {
    return searchData;
  }
  return [];
};

export const checkAndReturnActualDateOrTime = (val: string) => {
  const actualDate = format(new Date(val), "MMM-dd-yyyy");
  const today = format(new Date(), "MMM-dd-yyyy");
  const currentDateYear = new Date().getFullYear();
  const actualDateYear = new Date(val).getFullYear();

  if (actualDate === today) {
    return format(new Date(val), "h:mm a");
  }
  if (currentDateYear === actualDateYear) {
    return format(new Date(val), "dd MMM");
  }
  return actualDate;
};

export const customRandomNumberGenerator = (max: number | null) => {
  const constNum1 = 1664525;
  const constNum2 = 1013904223;
  let totalNum = Date.now();
  // eslint-disable-next-line no-bitwise
  totalNum = (constNum2 * totalNum + constNum1) & 0x7fffffff;
  if (max) {
    return (totalNum % max) + 1;
  }
  return totalNum + 1;
};

// Get font color based on the background Color
export const fontColorBasedOnBgColor = (
  hex: string | undefined,
  lightColor: string,
  darkColor: string
): string => {
  if (hex) {
    const match = hex.match(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i);
    if (!match) {
      return darkColor;
    }

    const [, red, green, blue] = match;
    const Luminance =
      0.2126 * parseInt(red, 16) +
      0.7152 * parseInt(green, 16) +
      0.0722 * parseInt(blue, 16);
    return Luminance > 186 ? darkColor : lightColor;
  }
  return darkColor;
};

export function formatDateOrString(
  inputValue: any,
  desiredFormat = "yyyy-MM-dd"
) {
  const parsedDate = parseISO(inputValue);
  if (isValid(parsedDate)) {
    return format(parsedDate, desiredFormat);
  }
  return inputValue;
}

export const convertTitleCase = (name: string) => {
  const splitName = name.split("_");
  let titleCase = "";
  if (splitName.length > 1) {
    const capitalizedParts = splitName.map(
      (part) => part.charAt(0).toUpperCase() + part.substr(1).toLowerCase()
    );
    titleCase = capitalizedParts.join(" ");
  } else {
    titleCase = name.charAt(0).toUpperCase() + name.substr(1).toLowerCase();
  }
  return titleCase;
};

/**
 * Converts an array of objects to a CSV string.
 * @param data - Array of objects to be converted to CSV.
 * @returns CSV string.
 */
const convertToCSV = (
  data: {
    [key: string]: any;
  }[]
): string => {
  if (data.length === 0) {
    return "";
  }

  const headers = Object.keys(data[0]);
  const csvRows: string[] = [
    headers.join(","), // header row
    ...data.map((row) =>
      headers.map((header) => JSON.stringify(row[header], replacer)).join(",")
    ), // data rows
  ];

  return csvRows.join("\n");
};

/**
 * Replacer function for JSON.stringify to handle escaping double quotes.
 * @param key - The key being stringified.
 * @param value - The value being stringified.
 * @returns The processed value.
 */
const replacer = (key: string, value: any) => {
  if (typeof value === "string") {
    return value.replace(/"/g, '""');
  }
  return value;
};

/**
 * Triggers a CSV download for the given data.
 * @param data - The data to be converted to CSV and downloaded.
 * @param filename - The name of the file to be downloaded.
 */
export const downloadCSV = (
  data: {
    [key: string]: any;
  }[],
  filename: string
) => {
  const csvString = convertToCSV(data);
  const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
