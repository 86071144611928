// ** package **
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// ** Component **
import Icon from "components/Icon";
import SettingMenuItem from "./SettingMenuItem";

// ** Redux **
import { getCurrentUser } from "redux/slices/authSlice";
import { setSideBarToggle } from "redux/slices/generalSettingSlice";

// ** constant **
import { SIDEBAR_DATA } from "../constants";

const SettingSidebar = () => {
  const dispatch = useDispatch();

  const userInfo = useSelector(getCurrentUser);

  const [currentPath, setCurrentPath] = useState("");

  return (
    <>
      <div className="setting__sidebar w-[286px] shadow-raiseShadow duration-300 3xl:w-[270px]">
        <div className="inner__wrapper h-full overflow-y-auto hideScrollbar">
          <div className="setting__menu__items">
            {SIDEBAR_DATA.map((menu, index) => {
              return menu.accessBy ? (
                menu.accessBy?.toLowerCase() ===
                userInfo?.userRole?.toLowerCase() ? (
                  <SettingMenuItem
                    menu={menu}
                    key={index}
                    setCurrentPath={setCurrentPath}
                    currentPath={currentPath}
                  />
                ) : (
                  <></>
                )
              ) : (
                <SettingMenuItem
                  menu={menu}
                  key={index}
                  setCurrentPath={setCurrentPath}
                  currentPath={currentPath}
                />
              );
            })}
          </div>
        </div>
        <div className="desktopCollapse__text text-[20px] font-AcuminPro__Semibold text-textDark -scale-x-100 -scale-y-100 hidden xl:hidden">
          Setting
        </div>
      </div>
      <Icon
        className="responsiveSidebarClose__btn z-[14] duration-300 before:content-[''] before:duration-300 before:absolute before:top-0 before:left-0 before:w-full before:h-full mobileCloseBtn textDarkHoverWhite !fixed top-[10px] left-[-100px] w-[32px] h-[32px] rounded-full bg-bgWhiteCarpet p-[7px] hover:bg-primaryColor lg:flex"
        name="close"
        onClick={() => {
          dispatch(setSideBarToggle({ isSideBarOpen: false }));
        }}
      />
      <span
        className="responsiveOverlay fixed top-0 left-0 w-screen h-screen bg-[#2726354d] z-[13] hidden"
        onClick={() => {
          dispatch(setSideBarToggle({ isSideBarOpen: false }));
        }}
      />
    </>
  );
};

export default SettingSidebar;
