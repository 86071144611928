// ** Packages **
import { Controller, Path } from "react-hook-form";

// ** CSS **
// import "./style/radioButton.css";

// ** Components **
import Label from "components/FormField/components/common/Label";
import HelperText from "components/FormField/components/common/HelperText";

// ** Types **
import { RadioButtonPropsTypes } from "components/FormField/types/formField.types";
import { DynamicObjectType } from "types";

const RadioButtonField = <TFormValues extends Record<string, unknown>>(
  fieldProps: RadioButtonPropsTypes<TFormValues>
) => {
  const {
    id,
    name,
    errors,
    register,
    control,
    options = [],
    className = "",
    required,
    fieldLabelClass = "",
    labelClass = "",
    errorClass = "",
    disabled = false,
    wrapperClass = "",
    mainWrapper = "",
    CustomRadioBtnSingle = "",
    value,
    label,
    onChange = () => ({}),
    defaultChecked = false,
  } = fieldProps;

  const renderInput = ({
    value: optionValue,
    name,
    selected,
  }: {
    value?: string | number | readonly string[];
    name?: Path<TFormValues>;
    selected?: boolean;
  }) => {
    if (control && name) {
      return (
        <Controller
          name={name}
          control={control}
          render={({
            field: { onChange: innerOnChange, value: innerValue },
          }) => {
            const controlValue = (innerValue as DynamicObjectType)?.value
              ? (innerValue as DynamicObjectType)?.value
              : innerValue;
            const checked =
              controlValue?.toString() === optionValue?.toString();

            return (
              <input
                type="radio"
                id={id}
                autoComplete="off"
                value={optionValue}
                key={optionValue?.toString()}
                checked={checked}
                onChange={innerOnChange}
                className={`input__carpet !pl-[50px] ${className}`}
              />
            );
          }}
        />
      );
    }
    return (
      <input
        id={id}
        value={optionValue}
        type="radio"
        autoComplete="off"
        disabled={disabled}
        onChange={onChange}
        className={className}
        {...(name && !register
          ? { name }
          : register && name && register(name, { onChange }))}
        key={optionValue?.toString()}
        {...(name && !register
          ? { checked: selected }
          : { defaultChecked: selected })}
      />
    );
  };

  const radioWrapper = (wrapperProps: {
    label: string | undefined;
    value?: string | number;
    selected: boolean;
    index: number;
  }) => {
    const { label, value, selected, index } = wrapperProps;
    return (
      <div
        className={`customRadio__carpetSingle ${CustomRadioBtnSingle}`}
        key={index}
      >
        <div
          className={`customRadio__carpet ${disabled ? "disabled" : ""} ${
            !label ? "without__label" : ""
          } ${wrapperClass}`}
          key={value?.toString()}
        >
          {renderInput({
            name,
            value,
            selected,
          })}
          {label ? (
            <Label id={id} label={label} labelClass={labelClass} />
          ) : (
            <label className="label__carpet" />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={`${label ? "field__wrapper" : ""} ${mainWrapper}`}>
      {label ? (
        <Label
          id={id}
          label={label}
          required={required}
          labelClass={fieldLabelClass}
        />
      ) : (
        <></>
      )}
      {!options.length
        ? radioWrapper({
            label,
            value,
            selected: defaultChecked,
            index: 0,
          })
        : options.map((option, index) =>
            radioWrapper({
              label: option.label,
              value: option?.value,
              selected: Boolean(option?.selected) || Boolean(option?.checked),
              index,
            })
          )}
      {errors?.message && (
        <HelperText
          helperText={errors?.message || ""}
          helperTextClass={`error__text ${errorClass}`}
        />
      )}
    </div>
  );
};

export default RadioButtonField;
