import Icon from "components/Icon";

const CommonReportCard = () => {
    return (
        <div className="commonReports__Card bg-bgWhiteCarpet rounded-[20px] p-[25px] h-full">
            <Icon className="w-[120px] h-[120px] mx-auto rounded-full p-[20px] mb-[26px] before:bg-primaryColor before:opacity-10" name="report1" fill="var(--primaryColor)" />
            <div className="lastRun text-[14px] text-primaryColor font-AcuminPro__Regular mb-[4px]">Last run 08/28/23</div>
            <h3 className="reportName text-[20px] font-AcuminPro__Regular text-textDark">Monthly Leads</h3>
        </div>
    );
}
export default CommonReportCard;