// ** Packages **
import { useState } from "react";

// ** Custom Components **
import HelperText from "components/FormField/components/common/HelperText";
import Label from "components/FormField/components/common/Label";
import Icon from "components/Icon";

// ** Types **
import { TextareaPropsType } from "components/FormField/types/formField.types";

// ** CSS **
// import "./style/textarea.css";

const TextAreaField = <TFormValues extends Record<string, unknown>>(
  fieldProps: TextareaPropsType<TFormValues>
) => {
  const {
    id,
    name,
    label,
    errors,
    register,
    onChange,
    value = "",
    icon = "",
    className = "",
    iconClass = "",
    labelClass = "",
    errorClass = "",
    maxLength = null,
    required = false,
    disabled = false,
    placeholder = "",
    wrapperClass = "",
    fieldWrapperClassName = "",
    fieldBGClassName = "",
    isIconRight = false,
    lengthVisible = false,
  } = fieldProps;

  // ** State **
  const [descLength, setDescLength] = useState<number>(0);

  const renderTextArea = () => {
    return (
      <textarea
        id={id}
        autoComplete="off"
        required={required}
        disabled={disabled}
        onChange={onChange}
        {...(maxLength && { maxLength })}
        {...(name && { name })}
        {...(value && { value })}
        placeholder={placeholder}
        className={`textarea__carpet hideScrollbar ${className}`}
        {...(register && name && register(name))}
        onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          const updatedLength = e.target.value.length;
          setDescLength(updatedLength);
        }}
        onFocus={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          const updatedLength = e.target.value.length;
          setDescLength(updatedLength);
        }}
      />
    );
  };

  return (
    <div
      className={`field__wrapper ${
        errors?.message ? "field__has__error" : ""
      } ${disabled ? "disable" : ""} ${fieldWrapperClassName}`}
    >
      {label && (
        <Label label={label} required={required} labelClass={labelClass} />
      )}
      <div
        className={`field__inner__wrapper textarea ${
          icon ? "field__has__icon" : ""
        } ${isIconRight ? "icon__right" : ""} ${wrapperClass}`}
      >
        {renderTextArea()}
        {label && (
          <Label label={label} required={required} labelClass={labelClass} />
        )}
        <span className={`bgWrapper ${fieldBGClassName}`} />
        {icon && <Icon name={icon} className={iconClass || ""} />}
      </div>
      {maxLength && lengthVisible ? (
        <div className="flex items-center justify-end text-[10px] font-Biotif__Regular text-textSecondary">
          <span className="inline-block mr-[3px]">{descLength}</span>
          {" / "}
          <span className="inline-block ml-[3px] text-textDark">
            {maxLength}
          </span>
        </div>
      ) : (
        <></>
      )}

      {errors?.message && (
        <HelperText
          helperText={errors?.message || ""}
          helperTextClass={`error__text ${errorClass}`}
        />
      )}
    </div>
  );
};

export default TextAreaField;
