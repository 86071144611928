// ** Packages **
import { AxiosRequestConfig } from "axios";

// ** Custom Hooks **
import { useAxiosPost, useAxiosGet } from "hooks/useAxios";
import { PostRequestType } from "types/service.types";

const AUTH_API_BASE_PATH = "/auth";
const USER_API_BASE_PATH = "/user";

export const useLoggedUserAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();

  const loggedUserAPI = async (data: object) => {
    return callApi(`${USER_API_BASE_PATH}/logged-in-user`, data);
  };

  return { loggedUserAPI, isLoading, isError, isSuccess };
};

export const useLoginAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const loginAPI = async ({ config = {}, data }: PostRequestType) => {
    return callApi(`${AUTH_API_BASE_PATH}/login`, data, config);
  };
  return { loginAPI, isLoading, isError, isSuccess };
};

export const useResetPasswordAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const resetPasswordAPI = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${AUTH_API_BASE_PATH}/set-password`, data, config);
  };
  return { resetPasswordAPI, isLoading, isError, isSuccess };
};

export const useEmailExistAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const emailExistAPI = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${AUTH_API_BASE_PATH}/email-exist`, data, config);
  };
  return { emailExistAPI, isLoading, isError, isSuccess };
};

export const useUpdatePasswordAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const updatePasswordAPI = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${AUTH_API_BASE_PATH}/set-user-password`, data, config);
  };
  return { updatePasswordAPI, isLoading, isError, isSuccess };
};

export const useForgotPasswordAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const forgotPasswordAPI = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${AUTH_API_BASE_PATH}/forgot-password`, data, config);
  };
  return { forgotPasswordAPI, isLoading, isError, isSuccess };
};

export const LogoutAPI = () => {
  // ** Custom Hooks **
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosGet();

  const logoutAPI = async (data: object) => {
    return callApi(`${AUTH_API_BASE_PATH}/logout`, data);
  };

  return { logoutAPI, isLoading, isError, isSuccess };
};

export const useIsValidateTokenAPI = () => {
  const [callApi, { isLoading, isError, isSuccess }] = useAxiosPost();
  const isValidateTokenAPI = async (
    data: object,
    config: AxiosRequestConfig<object> = {}
  ) => {
    return callApi(`${AUTH_API_BASE_PATH}/token-validate`, data, config);
  };
  return {
    isValidateTokenAPI,
    isLoading,
    isError,
    isSuccess,
  };
};
