export const ALLOWED_MIME_TYPES = Object.freeze([
  "image/png",
  "image/jpg",
  "image/jpeg",
]);

export const DATE_FORMAT_ARRAY = Object.freeze([
  { label: "DD/MM/YYYY", value: "dd/MM/yyyy" },
  { label: "YYYY/MM/DD", value: "yyyy/MM/dd" },
  { label: "MM/DD/YYYY", value: "MM/dd/yyyy" },
]);

export const ACCESSIBILITY = [
  { label: "Public", value: "public" },
  { label: "Private", value: "private" },
];

export const TAGS_COLOR = Object.freeze([
  "#1776ba",
  "#27ae60",
  "#ff3c3c",
  "#ffbf00",
  "#000000",
  "#6b31a0",
  "#3a25f1",
  "#91139e",
  "#076f9b",
]);
