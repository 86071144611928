// ** Packages **
import { Controller } from "react-hook-form";
import ReactSelect, { MultiValue, SingleValue } from "react-select";

// ** Component **
import HelperText from "components/FormField/components/common/HelperText";
import Label from "components/FormField/components/common/Label";
import Icon from "components/Icon";

// ** Types **
import {
  Option,
  ReactSelectPropsTypes,
} from "components/FormField/types/formField.types";

// ** CSS **
// import "../style/select.css";

const SelectField = <TFormValues extends Record<string, unknown>>(
  fieldProps: ReactSelectPropsTypes<TFormValues>
) => {
  const {
    name,
    label = "",
    errors,
    value,
    control,
    required,
    icon,
    placeholder,
    getOnChange, // EX. ()=>{{label:"",value:""}}
    isClearable,
    options = [],
    iconClass = "",
    errorClass = "",
    isMulti = false,
    labelClass = "",
    OptionComponent,
    noOptionsMessage,
    disabled = false,
    wrapperClass = "",
    isLoading = false,
    isIconRight = false,
    fieldWrapperClassName = "",
    fieldBGClassName = "",
    isSearchable = false,
    singleValueComponent,
    menuPlacement = "auto",
    menuPosition = "fixed",
    onChange: onCustomChange,
  } = fieldProps;
  return (
    <div
      className={`field__wrapper ${
        errors?.message || errors?.value?.message ? "field__has__error" : ""
      } ${disabled ? "disable" : ""} ${fieldWrapperClassName}`}
    >
      <div className={`selectCarpet ${wrapperClass}`}>
        {label && (
          <Label
            id={name}
            label={label}
            labelClass={labelClass}
            required={required}
          />
        )}
        <div
          className={`field__inner__wrapper ${icon ? "field__has__icon" : ""} ${
            isIconRight ? "icon__right" : ""
          }`}
        >
          {control ? (
            name && (
              <Controller
                name={name}
                control={control}
                render={({ field: { onChange, value: innerValue, ref } }) => (
                  <>
                    <ReactSelect
                      menuPortalTarget={document.body}
                      ref={ref}
                      options={options}
                      isMulti={isMulti}
                      key={Number(value)}
                      isLoading={isLoading}
                      isDisabled={disabled}
                      className="basic-single"
                      isClearable={isClearable}
                      placeholder={placeholder}
                      isSearchable={isSearchable}
                      // menuPosition={menuPosition}
                      menuPosition={menuPosition}
                      classNamePrefix="selectCarpet"
                      menuPlacement={menuPlacement}
                      {...(noOptionsMessage && { noOptionsMessage })}
                      value={
                        innerValue as MultiValue<Option> | SingleValue<Option>
                      }
                      onChange={(selectedOption, e) => {
                        onChange(selectedOption);
                        getOnChange?.(selectedOption);
                        onCustomChange?.(selectedOption, e);
                      }}
                      components={{
                        ...(OptionComponent && { Option: OptionComponent }),
                        ...(singleValueComponent && {
                          SingleValue: singleValueComponent,
                        }),
                      }}
                    />
                  </>
                )}
              />
            )
          ) : (
            <ReactSelect
              menuPortalTarget={document.body}
              options={options}
              isMulti={isMulti}
              value={
                value as unknown as MultiValue<Option> | SingleValue<Option>
              }
              key={Number(value)}
              isLoading={isLoading}
              isDisabled={disabled}
              className="basic-single"
              placeholder={placeholder}
              isClearable={isClearable}
              isSearchable={isSearchable}
              menuPosition={menuPosition}
              classNamePrefix="selectCarpet"
              menuPlacement={menuPlacement}
              {...(noOptionsMessage && { noOptionsMessage })}
              onChange={(selectedOption, e) => {
                onCustomChange?.(selectedOption, e);
                getOnChange?.(selectedOption);
              }}
              components={{
                ...(OptionComponent && { Option: OptionComponent }),
                ...(singleValueComponent && {
                  SingleValue: singleValueComponent,
                }),
              }}
            />
          )}
          {label && (
            <Label
              id={name}
              label={label}
              labelClass={labelClass}
              required={required}
            />
          )}
          <span className={`bgWrapper ${fieldBGClassName}`} />
          {icon && <Icon className={iconClass} name={icon} />}
        </div>
      </div>

      {errors?.message && (
        <HelperText
          helperText={errors?.message || ""}
          helperTextClass={`error__text ${errorClass}`}
        />
      )}
    </div>
  );
};

export default SelectField;
