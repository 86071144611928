// ** Packages **
import _ from "lodash";
import { useEffect, useState } from "react";
import { Controller, useWatch } from "react-hook-form";

// ** CSS **
// import "./style/fileField.css";

// ** Custom Component **
import Button from "components/Button";
import IconButton from "components/Button/IconButton";
import Icon from "components/Icon";
import Image from "components/Image";
import HelperText from "./HelperText";
import Label from "./Label";

// ** Service **
import { getPresignedImageUrl } from "services/wasabi.service";

// ** Type **
import { FilePropsType } from "components/FormField/types/formField.types";

// ** Helper **
import {
  checkFileFormat,
  fileSizeGenerator,
} from "components/FormField/helper";
import { downloadAttachmentFile } from "helper";

const FileField = <TFormValues extends Record<string, unknown>>(
  fieldProps: FilePropsType<TFormValues>
) => {
  const {
    id,
    errors,
    value,
    control,
    setValue,
    name = "",
    label = "",
    minSize = 0,
    className = "",
    errorClass = "",
    maxSize = 10240,
    required = false,
    disabled = false,
    hideFileList = false,
    allowedFormat = [],
    onBlur = () => ({}),
    onFocus = () => ({}),
    setError = () => ({}),
    onKeyDown = () => ({}),
    clearErrors = () => ({}),
    onChange = () => ({}),
  } = fieldProps;

  const formValues = useWatch({ control });
  const [attachment, setAttachments] = useState<any[]>([]);
  const [hasInitial, setIsInitial] = useState<boolean>(false);
  const initialInfo = Array.isArray(formValues?.[name])
    ? formValues?.[name]
    : [];

  useEffect(() => {
    if (!hasInitial && initialInfo?.length) {
      setAttachments([...initialInfo]);
      setIsInitial(true);
    }
  }, [initialInfo]);

  useEffect(() => {
    setValue?.(name, attachment as any);
  }, [attachment]);

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      let errorMsgArr: string[] = [];
      const largeErrorMsgArr: string[] = [];
      const minErrorMsgArr: string[] = [];
      const FilteredFiles: File[] = [];

      [...e.target.files].forEach((file) => {
        const type = _.cloneDeep(file.type);
        const typeError = checkFileFormat({ allowedFormat, errorMsgArr, type });
        if (typeError?.length) {
          errorMsgArr = [...typeError];
        } else if (file.size / 1024 >= maxSize) {
          largeErrorMsgArr.push(file.name);
        } else if (file.size / 1024 <= minSize) {
          minErrorMsgArr.push(file.name);
        }
        FilteredFiles.push(file);
      });

      if (FilteredFiles.length) {
        setAttachments((prev) => [...prev, ...FilteredFiles]);
        clearErrors(name);
      }

      if (errorMsgArr.length) {
        const isSingleError = errorMsgArr?.length === 1;
        setError(name, {
          type: "custom",
          message: `Only ${errorMsgArr.join(", ")} ${
            isSingleError ? "format" : "formats"
          } ${isSingleError ? "is" : "are"} allowed`,
        });
      }
      if (largeErrorMsgArr.length) {
        setError(name, {
          type: "custom",
          message: `File size is too large, it must be less than ${maxSize} KB.`,
        });
      }
      if (minErrorMsgArr.length && minSize) {
        setError(name, {
          type: "custom",
          message: `File size is too small, it must be greater than ${minSize} KB.`,
        });
      }
    }
  };

  const deleteAttachment = (id: number) => {
    setAttachments((prev) => {
      return prev.filter((_val, index) => id !== index);
    });
    clearErrors(name);
  };

  return (
    <div className="field__wrapper">
      {label && <Label label={label} required={required} />}
      <div className="field__inner__wrapper">
        <div className="fileUpload__wrapper h-[40px] rounded-[10px] rounded-l-[6px] shadow-raiseShadow py-[2px] flex items-center bg-bgWhiteSD">
          <p className="text-[14px] font-AcuminPro__Regular text-textDark whitespace-pre overflow-hidden text-ellipsis pl-[14px] pr-[14px] w-full">
            {attachment?.[0]?.name || "Choose File to upload"}
          </p>
          <div className="upload__btn shrink-0 cursor-pointer inline-flex items-center relative">
            {control && name && (
              <Controller
                name={name}
                control={control}
                render={({
                  field: { onChange: innerOnChange, name: innerName },
                }) => {
                  return (
                    <>
                      <input
                        id={id}
                        multiple
                        type="file"
                        value={value}
                        onBlur={onBlur}
                        name={innerName}
                        onFocus={onFocus}
                        autoComplete="off"
                        disabled={disabled}
                        onChange={(e) => {
                          handleFileSelect(e);
                          innerOnChange(e);
                          onChange(e);
                        }}
                        onKeyDown={onKeyDown}
                        accept={`${allowedFormat
                          ?.map((e) => e.value)
                          ?.join(",")}`}
                        className={`cursor-pointer absolute top-0 left-0 w-full h-full z-[3] opacity-0 ${className}`}
                      />
                    </>
                  );
                }}
              />
            )}
            <Button className="primaryBtn !rounded-l-0 before:!rounded-l-0">
              Upload File
            </Button>
          </div>
        </div>
      </div>
      {errors?.message && (
        <HelperText
          helperText={errors?.message || ""}
          helperTextClass={`error__text ${errorClass}`}
        />
      )}
      {!hideFileList ? (
        <div className="attachments__up__wrapper mt-[20px]">
          {([...attachment] || [])?.map((val: any, index: number) => {
            const file = val as File;
            const fileSize = fileSizeGenerator(file?.size);
            return (
              <div
                className="attachments__box flex items-center pb-[10px] mb-[10px] border-b-[1px] border-b-borderPrimary last:mb-0 last:pb-0 last:border-b-0"
                key={index + 1}
              >
                <div className="attachments__details flex items-center w-[calc(100%_-_125px)] pr-[10px]">
                  {val ? (
                    <Image
                      imgPath={val?.value || val}
                      NoNameLetterClass="!text-[14px] pt-[3px]"
                      avatarWrapperClassName="w-[36px] h-[36px] shrink-0 mr-[8px]"
                      avatarInnerWrapperClassName="!rounded-[5px]"
                      serverPath
                      iconName="file"
                    />
                  ) : (
                    <Icon
                      className="w-[20px] h-[20px] mr-[8px] relative top-[-2px] shrink-0"
                      fill="var(--primaryColorSD)"
                      name="file"
                    />
                  )}

                  <span className="attachments__name pt-[3px] whitespace-pre overflow-hidden text-ellipsis text-[14px] font-AcuminPro__Regular text-textDark">
                    {file?.name || ""}
                  </span>
                </div>
                <div
                  className={`attachments__size pt-[3px] w-[100px] text-[14px] font-AcuminPro__Regular text-textDark pr-[8px] ${
                    !(val instanceof File) ? "" : "mr-[25px]"
                  } `}
                >{`${fileSize.size} ${fileSize.sizeType}`}</div>

                {!(val instanceof File) ? (
                  <div className="inline-flex shrink-0">
                    <IconButton
                      icon="arrowDownBold"
                      className=""
                      onClick={async (e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        if (!(val instanceof File)) {
                          const imgURL = val?.value;
                          if (imgURL) {
                            const downloadURL = await getPresignedImageUrl(
                              imgURL,
                              undefined,
                              undefined,
                              true
                            );
                            downloadAttachmentFile({
                              url: downloadURL,
                              fileName: file.name || "Carpet",
                            });
                          }
                        }
                      }}
                    />
                  </div>
                ) : null}
                <div className="inline-flex  ml-[10px] shrink-0">
                  <IconButton
                    icon="trash"
                    className=""
                    onClick={() => {
                      deleteAttachment(index);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default FileField;
