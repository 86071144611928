// ** Packages **
import DataTable from "react-data-table-component";

// ** Component **
import Icon from "components/Icon";
import TableLoader from "components/Loaders/TableLoader";
import NoDataFound from "components/NoDataFound";

// ** Type **
import { TablePropsType } from "./types";

const Table = (props: TablePropsType) => {
  const { columns, data, selectionDisabled = false, isLoading } = props;
  const sortIcon = <Icon name="arrowDownBold" />;
  const selectProps = {
    indeterminate: (isIndeterminate: boolean) => isIndeterminate,
  };

  return (
    <DataTable
      className="responsiveDataTable"
      pagination
      data={data}
      columns={columns}
      sortIcon={sortIcon}
      progressPending={isLoading}
      progressComponent={<TableLoader />}
      noDataComponent={
        <NoDataFound imageClassName="block w-[250px] max-w-full mx-auto" />
      }
      selectableRows={!selectionDisabled}
      {...(!selectionDisabled && {
        selectableRowsComponentProps: selectProps,
      })}
    />
  );
};

export default Table;
