// ** Packages **
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// ** Redux **
import { RootStateType } from "redux/store";

// ** Types **
import { Option } from "components/FormField/types/formField.types";

export type GeneralSettingSliceType = {
  roles?: Option[];
  states?: Option[];
  isSideBarOpen?: boolean;
};

const initialState: GeneralSettingSliceType = {
  roles: [],
  states: [],
  isSideBarOpen: false,
};

const slice = createSlice({
  name: "generalSettings",
  initialState,
  reducers: {
    setRoleList(
      state: GeneralSettingSliceType,
      action: PayloadAction<GeneralSettingSliceType>
    ) {
      state.roles = action.payload.roles;
    },
    setStateList(
      state: GeneralSettingSliceType,
      action: PayloadAction<GeneralSettingSliceType>
    ) {
      state.states = action.payload.states;
    },
    setSideBarToggle(
      state: GeneralSettingSliceType,
      action: PayloadAction<GeneralSettingSliceType>
    ) {
      state.isSideBarOpen = action.payload.isSideBarOpen;
    },
  },
});

export const { reducer } = slice;

export const { setRoleList, setStateList, setSideBarToggle } = slice.actions;

export const getRoleList = (state: RootStateType) => state.generalSetting.roles;

export const getStateList = (state: RootStateType) =>
  state.generalSetting.states;

export const getIsSideBarToggle = (state: RootStateType) =>
  state.generalSetting.isSideBarOpen;

export default slice;
