// ** Custom Component **
import { InputField } from "components/FormField";

// ** Type **
import { TextInputProps } from "components/FormField/types/formField.types";

const TextField = <TFormValues extends Record<string, unknown>>(
  fieldProps: TextInputProps<TFormValues>,
) => {
  return <InputField  {...fieldProps}  type="text" />;
};

export default TextField;
