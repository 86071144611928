const AddFile = () => {
  return (
    <>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="">
        <path d="M20.1 20.7291C20.1 21.3846 19.548 21.8528 18.996 21.8528H16.696V24.194C16.696 24.8495 16.144 25.3177 15.592 25.3177C15.04 25.3177 14.304 24.8495 14.304 24.194V21.8528H12.004C11.36 21.8528 10.9 21.291 10.9 20.7291C10.9 20.1672 11.452 19.6054 12.004 19.6054H14.304V17.2642C14.304 16.6087 14.856 16.0468 15.408 16.0468C15.96 16.0468 16.512 16.6087 16.512 17.2642V19.6054H18.904C19.548 19.5117 20.1 20.0736 20.1 20.7291ZM27 14.2676V24.194C27 27.3779 24.424 30 21.296 30H9.704C6.576 30 4 27.3779 4 24.194V7.80602C4 4.62207 6.576 2 9.704 2H14.856C16.972 2 18.996 2.84281 20.56 4.43478L24.516 8.55518C26.08 9.95987 27 12.1137 27 14.2676ZM18.996 6.02676C18.628 5.65217 18.26 5.37124 17.8 5.0903V10.1472C17.8 10.8027 18.352 11.2709 18.904 11.2709H23.872C23.596 10.8027 23.32 10.4281 22.952 10.0535L18.996 6.02676ZM24.7 14.2676C24.7 14.0803 24.7 13.893 24.608 13.7057H18.904C16.972 13.7057 15.5 12.1137 15.5 10.2408V4.34114C15.316 4.34114 15.132 4.24749 14.948 4.24749H9.704C7.864 4.34114 6.3 5.93311 6.3 7.80602V24.194C6.3 26.1605 7.864 27.6589 9.704 27.6589H21.204C23.136 27.6589 24.608 26.0669 24.608 24.194L24.7 14.2676Z" />
      </svg>
    </>
  );
};
export default AddFile;
